import React, { useState, useEffect } from "react";
import styles from "./ServiceAnalytics.module.scss";
import { Service } from "pages/Services/Service";
import { axiosMyShop } from "plugins/axios";

const ServiceAnalyticsList = () => {
  const [services, setServices] = useState([]);
  const [wasLastList, setWasLastList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(2);

  const onScroll = (event) => {
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight >=
      event.target.scrollHeight - 10;
    if (scrollBottom && currentPage < nextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const getServices = async () => {
      let resServices = await axiosMyShop.getMyGoods(
        `page=${currentPage}&catalog_type=services`
      );

      if (!resServices.next) {
        setWasLastList(true);
        setNextPage(currentPage);
      } else {
        setNextPage(currentPage + 1);
      }

      setServices([
        ...(currentPage === 1 ? [] : services),
        ...(resServices?.results?.products || []),
      ]);
    };

    if (!wasLastList) {
      getServices();
    }
  }, [currentPage, wasLastList]);

  return (
    <div className={styles.service_analytics__cont_list}>
      <div className={styles.service_analytics__header}>
        <div className={styles.service_analytics__header__title}>
          Аналитика по услугам
        </div>
      </div>

      <div className={styles.service_analytics__services} onScroll={onScroll}>
        {services.length ? (
          services?.map((good) => (
            <Service
              key={good.id}
              element={good}
              disabledActions={true}
              isMine={true}
              position="relative"
              toPath="/seller/analytics/services_analytics"
            />
          ))
        ) : (
          <div className={styles.service_analytics__services__empty}>
            У вас нет центров
          </div>
        )}
      </div>
    </div>
  );
};

export { ServiceAnalyticsList };
