import React from "react";
import styles from "./ToggleSwitch.module.scss";

const ToggleSwitch = ({
  text = "",
  isSelected = false,
  onChange = () => {},
  ...rest
}) => (
  <div className={styles.toggle} style={{ ...rest }}>
    <label className={styles.switch}>
      <input
        type="checkbox"
        checked={isSelected}
        onChange={() => onChange(!isSelected)}
      />
      <span className={styles.slider} />
    </label>
    {text}
  </div>
);

export { ToggleSwitch };
