import vector_25 from "../../assets/img/vector_25.svg";
import main_photo from "../../assets/img/main_photo.svg";
import small_photo from "../../assets/img/small_photo.svg";
import arrow_2 from "../../assets/img/arrow_2.svg";
import arrow_green from "../../assets/img/arrow_green.svg";
import birdie from "../../assets/img/birdie.svg";

import styles from "./UpdateService.module.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { axiosSeller } from "plugins/axios";
import { useParams } from "react-router-dom";

const UpdateService = () => {
  const navigate = useNavigate();
  const [medicine, setMedicine] = useState([]);
  const [cities, setСities] = useState([]);
  const [typesOfService, setTypesOfService] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [openCategory, setOpenCategory] = useState([]);
  const params = useParams();

  const [openSelect, setOpenSelect] = useState({
    openMed: false,
    openСities: false,
    openTypesOfService: false,
  });

  const [serviceFields, setServiceFields] = useState({
    name: "",
    main_site: "",
    type_of_medicine: [],
    description: "",
    city: [],
    phone_number: "",
    address: "",
    whatsapp_phone: "",
    telegram_phone: "",
    types_of_service: [],
    old_photo: [],
  });

  const [pics, setPics] = useState([
    {
      id: 1,
      pic: main_photo,
      nameProductFields: "pic_1",
      picProductFields: "",
      oldPic: "",
      oldId: "",
    },
    {
      id: 2,
      pic: small_photo,
      nameProductFields: "pic_2",
      picProductFields: "",
      oldPic: "",
      oldId: "",
    },
    {
      id: 3,
      pic: small_photo,
      nameProductFields: "pic_3",
      picProductFields: "",
      oldPic: "",
      oldId: "",
    },
    {
      id: 4,
      pic: small_photo,
      nameProductFields: "pic_4",
      picProductFields: "",
      oldPic: "",
      oldId: "",
    },
    {
      id: 5,
      pic: small_photo,
      nameProductFields: "pic_5",
      picProductFields: "",
      oldPic: "",
      oldId: "",
    },
    {
      id: 6,
      pic: small_photo,
      nameProductFields: "pic_6",
      picProductFields: "",
      oldPic: "",
      oldId: "",
    },
    {
      id: 7,
      pic: small_photo,
      nameProductFields: "pic_7",
      picProductFields: "",
      oldPic: "",
      oldId: "",
    },
    {
      id: 8,
      pic: small_photo,
      nameProductFields: "pic_8",
      picProductFields: "",
      oldPic: "",
      oldId: "",
    },
    {
      id: 9,
      pic: small_photo,
      nameProductFields: "pic_9",
      picProductFields: "",
      oldPic: "",
      oldId: "",
    },
  ]);

  const [services, setServices] = useState([
    {
      service_name: "service_name_1",
      service_name_value: "",
      service_desc: "service_desc_1",
      service_desc_value: "",
      service_price_from: "service_price_from_1",
      service_price_from_value: "",
      service_price_to: "service_price_to_1",
      service_price_to_value: "",
    },
  ]);

  const getRawService = async () => {
    let result = await axiosSeller.getRawService(params.id);
    let newServices = [...services];

    result.services?.map((item, index) => {
      if (index === 0) {
        newServices[0].service_name_value = item.name;
        newServices[0].service_desc_value = item.description;
        newServices[0].service_price_from_value = item.price_from;
        newServices[0].service_price_to_value = item.price_to;
      }
      if (index > 0) {
        newServices.push({
          service_name: "service_name_" + (index + 1),
          service_name_value: item.name,
          service_desc: "service_desc_" + (index + 1),
          service_desc_value: item.description,
          service_price_from: "service_price_from_" + (index + 1),
          service_price_from_value: item.price_from,
          service_price_to: "service_price_to_" + (index + 1),
          service_price_to_value: item.price_to,
        });

        setServices(newServices);
      }
    });
    result.pic_urls?.map((item) => {
      let i = 0;
      let newPics = [...pics];
      while (i < pics.length) {
        if (newPics[i].oldId === item.id) {
          break;
        }
        if (pics[i].picProductFields === "" && pics[i].oldPic === "") {
          newPics[i].oldPic = item.pic_url;
          newPics[i].oldId = item.id;
          setPics(newPics);
          break;
        }
        i++;
      }
    });
    setServiceFields({
      ...serviceFields,
      name: result.name,
      address: result.address,
      city: result.city,
      description: result.description,
      main_site: result.main_site,
      phone_number: result.phone,
      telegram_phone: result.telegram_phone,
      whatsapp_phone: result.whatsapp_phone,
      type_of_medicine: result.type_of_medicine,
      types_of_service: result.types_of_service,
    });
  };

  const addService = () => {
    let serv = {
      service_name: "service_name_" + (services.length + 1),
      service_name_value: "",
      service_desc: "service_desc_" + (services.length + 1),
      service_desc_value: "",
      service_price_from: "service_price_from_" + (services.length + 1),
      service_price_from_value: "",
      service_price_to: "service_price_to_" + (services.length + 1),
      service_price_to_value: "",
    };
    setServices([...services, serv]);
  };

  const getMedicineCreateService = async () => {
    let result = await axiosSeller.getMedicineCreateService();
    setMedicine(result.types_of_medicine || []);
    setСities(result.cities || []);
    setTypesOfService(result.types_of_service || []);
  };

  const checkProperties = (obj) => {
    for (let key in obj) {
      if (
        (obj[key] === null || obj[key] == "" || obj[key] == []) &&
        key !== "old_photo"
      )
        return key;
    }
    return false;
  };

  const updateService = async () => {
    let i = 0;
    let newServiceFields = { ...serviceFields };
    while (i < pics.length) {
      if (pics[i].picProductFields !== "") {
        newServiceFields[pics[i].nameProductFields] = pics[i].picProductFields;
        setServiceFields(newServiceFields);
      } else if (pics[i].oldPic !== "") {
        newServiceFields.old_photo.push(pics[i].oldId);
        setServiceFields(newServiceFields);
      }
      i++;
    }
    services.map((item) => {
      if (
        item.service_name_value !== "" &&
        item.service_desc_value !== "" &&
        item.service_price_from_value !== "" &&
        item.service_price_to_value !== ""
      ) {
        newServiceFields[item.service_name] = item.service_name_value;
        newServiceFields[item.service_desc] = item.service_desc_value;
        newServiceFields[item.service_price_from] =
          item.service_price_from_value;
        newServiceFields[item.service_price_to] = item.service_price_to_value;
        setServiceFields({
          ...serviceFields,
          newServiceFields,
        });
      }
    });
    setErrorMessage("");
    let error = checkProperties(serviceFields);
    if (error) {
      setErrorMessage("Заполните все поля." + error);
    } else {
      let result = await axiosSeller.updateService(params.id, newServiceFields);
      if (result.status === 204) {
        navigate("/seller/shop/products");
      }
      if (result.status === 400) {
        if (
          result.data.Error === "Such product already exists" ||
          result.data.Error === "Product with such vendor code already exists"
        ) {
          setErrorMessage(
            "Такой товар уже существует.(У каждого продукта должен быть уникальный артикул.)"
          );
        }
        if (result.data.Error === "Necessary fields are not given") {
          setErrorMessage("Заполните все поля.");
        }
      }
    }
  };

  const addImages = (image) => {
    let i = 0;
    let newPics = [...pics];
    while (i < pics.length) {
      if (pics[i].picProductFields === "" && pics[i].oldPic === "") {
        newPics[i].picProductFields = image;
        setPics(newPics);
        break;
      }
      i++;
    }
  };

  const RemoveImages = (id) => {
    let newPics = [...pics];
    newPics[id].oldPic = "";
    newPics[id].oldId = "";
    newPics[id].picProductFields = "";
    setPics(newPics);
  };

  const addOrRemoveServiceFields = (item, field) => {
    if (serviceFields[field].find((element) => element === item.pk)) {
      setServiceFields({
        ...serviceFields,
        [field]: serviceFields[field].filter((n) => {
          return n != item.pk;
        }),
      });
    } else {
      setServiceFields({
        ...serviceFields,
        [field]: [...serviceFields[field], item.pk],
      });
    }
  };

  const showCategories = (category, field) => {
    return (
      <div style={{ marginLeft: "4vh" }}>
        {category?.children?.length === 0 || !category.children ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            onClick={() => {
              addOrRemoveServiceFields(category.obj, field);
            }}
          >
            {serviceFields[field].find(
              (element) => element === category.obj.pk
            ) ? (
              <img
                src={birdie}
                alt="birdie"
                style={{ height: "1.5vh", paddingRight: "1.5vh" }}
              />
            ) : null}
            <h1
              style={
                serviceFields[field].find(
                  (element) => element === category.obj.pk
                )
                  ? { color: "#20b900" }
                  : null
              }
              className={styles.empty_category}
            >
              {category.obj.name}
            </h1>
          </div>
        ) : (
          <div>
            <div
              className={
                openCategory.find((element) => element === category.obj.pk)
                  ? styles.open_category
                  : styles.category
              }
              onClick={() => {
                if (
                  openCategory.find((element) => element === category.obj.pk)
                ) {
                  setOpenCategory(
                    openCategory.filter((n) => {
                      return n != category.obj.pk;
                    })
                  );
                } else {
                  setOpenCategory([...openCategory, category.obj.pk]);
                }
              }}
            >
              <h1>{category.obj.name}</h1>
              {openCategory.find((element) => element === category.obj.pk) ? (
                <img
                  src={arrow_green}
                  alt="arrow_green"
                  style={{ height: "0.8vh", padding: "0 2vh" }}
                />
              ) : (
                <img
                  src={arrow_2}
                  alt="arrow_2"
                  style={{ height: "1.5vh", paddingLeft: "1vh" }}
                />
              )}
            </div>
            {openCategory.find((element) => element === category.obj.pk) && (
              <div className={styles.part_categories}>
                {category.children.map((item, index) =>
                  item?.children?.length === 0 || !category.children ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: "4vh",
                      }}
                      key={index}
                    >
                      {serviceFields[field].find(
                        (element) => element === item.obj.pk
                      ) ? (
                        <img
                          src={birdie}
                          alt="birdie"
                          style={{ height: "1.5vh", paddingRight: "1.5vh" }}
                        />
                      ) : null}
                      <h1
                        className={styles.empty_category}
                        style={
                          serviceFields[field].find(
                            (element) => element === item.obj.pk
                          )
                            ? { color: "#20b900" }
                            : null
                        }
                        onClick={() => {
                          addOrRemoveServiceFields(item.obj, field);
                        }}
                      >
                        {item.obj.name}
                      </h1>
                    </div>
                  ) : (
                    showCategories(item, field)
                  )
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const addNewService = () => {
    services.map((item) => {
      if (
        item.service_name_value !== "" &&
        item.service_desc_value !== "" &&
        item.service_price_from_value !== "" &&
        item.service_price_to_value !== ""
      ) {
        let newServiceFields = serviceFields;
        newServiceFields[item.service_name] = item.service_name_value;
        newServiceFields[item.service_desc] = item.service_desc_value;
        newServiceFields[item.service_price_from] =
          item.service_price_from_value;
        newServiceFields[item.service_price_to] = item.service_price_to_value;
        setServiceFields({
          ...serviceFields,
          newServiceFields,
        });
      }
    });
  };

  useEffect(() => {
    getMedicineCreateService();
    addNewService();
    getRawService();
  }, []);
  return (
    <div className={styles.container}>
      <Helmet title="Редактировать Услугу">
        <link rel="canonical" href="/seller/createservice" />
        <meta
          name="description"
          content='Форма добавления услуги на сайт "Shalfey".'
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div>
        <h1 className={styles.heading}>Редактирование услуги</h1>
      </div>
      <div style={{ display: "flex" }}>
        <div className={styles.part}>
          <div>
            <div className={styles.title}>
              <h3
                onClick={() => {
                  addNewService();
                }}
              >
                Основная информация
              </h3>
            </div>
            <div className={styles.content}>
              <input
                value={serviceFields.name}
                placeholder="Название"
                onChange={(event) => {
                  setServiceFields({
                    ...serviceFields,
                    name: event.target.value,
                  });
                }}
              />
              <input
                value={serviceFields.main_site}
                placeholder="Ссылка на ваш сайт"
                onChange={(event) => {
                  setServiceFields({
                    ...serviceFields,
                    main_site: event.target.value,
                  });
                }}
              />
              <input
                value={serviceFields.description}
                placeholder="Описание"
                onChange={(event) => {
                  setServiceFields({
                    ...serviceFields,
                    description: event.target.value,
                  });
                }}
              />

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                  <div
                    className={styles.medicine}
                    style={{ width: "19vw", marginRight: "2vh" }}
                    onClick={() => {
                      setOpenSelect({
                        openMed: !openSelect.openMed,
                      });
                    }}
                  >
                    <p>Вид медицины</p>
                    <img src={vector_25} alt="vector_25" />
                  </div>
                  {openSelect.openMed && (
                    <div className={styles.types_of_medicine}>
                      {medicine?.map((item, index) => (
                        <div
                          className={styles.type}
                          key={index}
                          onClick={() => {
                            setServiceFields({
                              ...serviceFields,
                              type_of_medicine: item.id,
                            });
                          }}
                        >
                          <p
                            className={
                              serviceFields.type_of_medicine === item.id &&
                              styles.open_medicine
                            }
                          >
                            {item.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className={styles.medicine}
                    style={{ width: "19vw" }}
                    onClick={() => {
                      setOpenSelect({
                        openTypesOfService: !openSelect.openTypesOfService,
                      });
                    }}
                  >
                    <p>Виды услуг</p>
                    <img src={vector_25} alt="vector_25" />
                  </div>
                  {openSelect.openTypesOfService && (
                    <div>
                      {typesOfService?.map((item, index) =>
                        showCategories(item, "types_of_service")
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.title_pic}>
              <h1>Фото</h1>
              <label
                for="picfile"
                style={{ color: "#20B900", cursor: "pointer" }}
              >
                Добавить фото
              </label>
              <input
                type="file"
                id="picfile"
                name="picfile"
                onChange={(event) => {
                  addImages(event.target.files[0]);
                }}
              />
            </div>
            <div className={styles.pics}>
              <div
                className={
                  (pics[0].picProductFields !== "" || pics[0].oldPic !== "") &&
                  styles.main_photo
                }
                onClick={() => {
                  RemoveImages(0);
                }}
              >
                <img
                  src={
                    pics[0].picProductFields !== ""
                      ? URL.createObjectURL(pics[0].picProductFields)
                      : pics[0].oldPic !== ""
                      ? pics[0].oldPic
                      : pics[0].pic
                  }
                  alt={pics[0].nameProductFields}
                  style={{ height: "28vh", width: "28vh" }}
                />
              </div>
              <div className={styles.small_photo}>
                {pics.slice(1).map((item, index) => (
                  <div
                    key={index}
                    className={
                      (item.picProductFields !== "" || item.oldPic !== "") &&
                      styles.photo
                    }
                    onClick={() => {
                      RemoveImages(item.id - 1);
                    }}
                  >
                    <img
                      src={
                        item.picProductFields !== ""
                          ? URL.createObjectURL(item.picProductFields)
                          : item.oldPic !== ""
                          ? item.oldPic
                          : item.pic
                      }
                      alt={item.nameProductFields}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.part}>
          <div>
            <div className={styles.title}>
              <h3>Контакты</h3>
            </div>
            <div className={styles.content}>
              <div
                className={styles.medicine}
                onClick={() => {
                  setOpenSelect({
                    openСities: !openSelect.openСities,
                  });
                }}
              >
                <p>Город</p>
                <img src={vector_25} alt="vector_25" />
              </div>
              {openSelect.openСities && (
                <div className={styles.types_of_medicine}>
                  {cities?.map((item, index) => (
                    <div
                      className={styles.type}
                      key={index}
                      onClick={() => {
                        setServiceFields({
                          ...serviceFields,
                          city: item.id,
                        });
                      }}
                    >
                      <p
                        className={
                          serviceFields.city === item.id && styles.open_medicine
                        }
                      >
                        {item.name}
                      </p>
                    </div>
                  ))}
                </div>
              )}
              <input
                value={serviceFields.address}
                placeholder="Адрес"
                onChange={(event) => {
                  setServiceFields({
                    ...serviceFields,
                    address: event.target.value,
                  });
                }}
              />
              <input
                value={serviceFields.phone_number}
                placeholder="Контактный номер телефона"
                onChange={(event) => {
                  setServiceFields({
                    ...serviceFields,
                    phone_number: event.target.value,
                  });
                }}
              />
              <div>
                <input
                  value={serviceFields.telegram_phone}
                  placeholder="Ссылка на Telegram"
                  style={{ width: "22vw" }}
                  onChange={(event) => {
                    setServiceFields({
                      ...serviceFields,
                      telegram_phone: event.target.value,
                    });
                  }}
                />
                <input
                  value={serviceFields.whatsapp_phone}
                  placeholder="Ссылка на What’s Up"
                  style={{ width: "22vw" }}
                  onChange={(event) => {
                    setServiceFields({
                      ...serviceFields,
                      whatsapp_phone: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className={styles.title}>
              <h3 style={{ margin: "8vh 0 2vh 0" }}>Услуги</h3>
            </div>
            <div className={styles.content}>
              <div className={styles.services}>
                {[...Array(services.length)].map((x, i) => (
                  <div style={{ marginBottom: "5vh" }} key={i}>
                    <input
                      value={services[i].service_name_value}
                      placeholder="Название"
                      onChange={(event) => {
                        let newServices = [...services];
                        newServices[i].service_name_value = event.target.value;
                        setServices(newServices);
                      }}
                    />
                    <input
                      value={services[i].service_desc_value}
                      placeholder="Описание"
                      onChange={(event) => {
                        let newServices = [...services];
                        newServices[i].service_desc_value = event.target.value;
                        setServices(newServices);
                      }}
                    />
                    <p>Стоимость услуги</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h1>от</h1>
                      <input
                        value={services[i].service_price_from_value}
                        placeholder="Цена"
                        style={{ width: "9vw", margin: "0 1vh" }}
                        type="number"
                        onChange={(event) => {
                          let newServices = [...services];
                          newServices[i].service_price_from_value =
                            event.target.value;
                          setServices(newServices);
                        }}
                      />
                      <h1>до</h1>
                      <input
                        value={services[i].service_price_to_value}
                        placeholder="Цена"
                        style={{ width: "9vw", margin: "0 1vh" }}
                        type="number"
                        onChange={(event) => {
                          let newServices = [...services];
                          newServices[i].service_price_to_value =
                            event.target.value;
                          setServices(newServices);
                        }}
                      />
                    </div>
                  </div>
                ))}
                <h2
                  onClick={() => {
                    addService();
                  }}
                >
                  + Добавить услугу
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {errorMessage && <p>{errorMessage}</p>}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "15px",
          }}
        >
          <button
            className={styles.cancel}
            onClick={() => navigate("/seller/showservice/" + params.id)}
          >
            Отмена
          </button>
          <button onClick={updateService} className={styles.further}>
            Сохранить
          </button>
        </div>
      </div>
    </div>
  );
};

export { UpdateService };
