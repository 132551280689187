import styles from "./TrendAnalytics.module.scss";
import React, { useEffect, useState } from "react";
import { axiosSeller } from "plugins/axios";

const TrendAnalytics = () => {
  const options = [
    {
      name: "Продажи по категориям",
      nameForRequest: "categories",
    },
    {
      name: "Продажи по виду медицины",
      nameForRequest: "type_of_medicine",
    },
    {
      name: "Продажи по назначению (тегам)",
      nameForRequest: "tags",
    },
    {
      name: "Продажи по брендам",
      nameForRequest: "brand",
    },
    {
      name: "Сезонность продаж",
      nameForRequest: "seasonality",
    },
    {
      name: "Взаимосвязь товаров",
      nameForRequest: "relationship",
    },
  ];

  const intervalOfTime = [
    {
      name: "Неделя",
      nameForRequest: "week",
    },
    {
      name: "Месяц",
      nameForRequest: "month",
    },
    {
      name: "3 месяца",
      nameForRequest: "quarter",
    },
    {
      name: "6 месяцев",
      nameForRequest: "half",
    },
    {
      name: "Год",
      nameForRequest: "year",
    },
  ];
  const colors = ["#EAF8E7", "#FFF3E9", "#E7EFF8", "#F4E7F8", "#FFECF0"];
  const [selectedOption, setSelectedOption] = useState("categories");
  const [selectedTime, setSelectedTime] = useState("week");
  const [category, setCategory] = useState([]);

  const getTrandAnalytics = async (period, type) => {
    try {
      if (type === "relationship") {
        let result = await axiosSeller.getRelationshipsAnalytics();
        let color_category = [].concat(
          ...Array.from({ length: Math.ceil(result.length / 5) }, () => colors)
        );
        let newCategory = [];
        result?.map((item, index) =>
          newCategory.push({
            category_name: item.category_name,
            category_count: item.category_percent,
            color: color_category[index],
          })
        );
        setCategory(newCategory);
      } else if (type === "seasonality") {
        let result = await axiosSeller.getSeasonalityAnalytics();
        let newCategory = [];
        result?.map((item) =>
          newCategory.push({
            category_name: item.category_name,
            category_percents: item.category_percents,
          })
        );
        setCategory(newCategory);
      } else {
        let result = await axiosSeller.getTrandAnalytics(period, type);
        let color_category = [].concat(
          ...Array.from({ length: Math.ceil(result.length / 5) }, () => colors)
        );
        let newCategory = [];
        result?.map((item, index) =>
          newCategory.push({
            category_name: item.category_name,
            category_count: item.category_count,
            color: color_category[index],
          })
        );
        setCategory(newCategory);
      }
    } catch (error) {
      console.error("Ошибка запроса:", error);
    }
  };

  useEffect(() => {
    getTrandAnalytics(selectedTime, selectedOption);
  }, [selectedOption, selectedTime]);
  return (
    <section className={styles.container}>
      <h1 className={styles.heading}>Трендовая аналитика</h1>
      <div>
        <div className={styles.analytics_options}>
          {options.map((item, index) => (
            <div
              key={index}
              className={
                item.nameForRequest === selectedOption
                  ? styles.active_option
                  : styles.option
              }
              style={
                index === 0
                  ? { borderRadius: "1vh 0 0 1vh", borderLeft: "0" }
                  : index === options.length - 1
                  ? { borderRadius: "0 1vh 1vh 0", borderRight: "0" }
                  : null
              }
              onClick={() => {
                setSelectedOption(item.nameForRequest);
              }}
            >
              <h1>{item.name}</h1>
            </div>
          ))}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "4vh",
          }}
        >
          <div className={styles.columns}>
            {selectedOption === "relationship" && (
              <h2>Эти товары часто покупают вместе:</h2>
            )}
            {category &&
              selectedOption !== "seasonality" &&
              category?.map((item, index) => (
                <div style={{ marginBottom: "4vh" }} key={index}>
                  <h1>{item.category_name}</h1>
                  <div className={styles.column}>
                    <div
                      style={{
                        height: "11vh",
                        width: item.category_count + "%",
                        backgroundColor: item.color,
                        borderRadius: "0 1.3vh 1.3vh 0 ",
                      }}
                    />
                    <p>{item.category_count}%</p>
                  </div>
                </div>
              ))}
            {category &&
              selectedOption === "seasonality" &&
              category?.map((item, index) => (
                <div key={index}>
                  <h4>{item.category_name}</h4>
                  <div className={styles.seasonality}>
                    <div className={styles.column_season}>
                      <h1>{item.category_percents?.autumn}%</h1>
                      <div
                        style={{
                          height: item.category_percents?.autumn / 1.3 + "%",
                          backgroundColor: colors[0],
                        }}
                      />
                      <p>Осень</p>
                    </div>
                    <div className={styles.column_season}>
                      <h1>{item.category_percents?.winter}%</h1>
                      <div
                        style={{
                          height: item.category_percents?.winter / 1.3 + "%",
                          backgroundColor: colors[1],
                        }}
                      />
                      <p>Зима</p>
                    </div>
                    <div className={styles.column_season}>
                      <h1>{item.category_percents?.spring}%</h1>
                      <div
                        style={{
                          height: item.category_percents?.spring / 1.3 + "%",
                          backgroundColor: colors[2],
                        }}
                      />
                      <p>Весна</p>
                    </div>
                    <div className={styles.column_season}>
                      <h1>{item.category_percents?.summer}%</h1>
                      <div
                        style={{
                          height: item.category_percents?.summer / 1.3 + "%",
                          backgroundColor: colors[3],
                        }}
                      />
                      <p>Лето</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {(selectedOption === "categories" ||
            selectedOption === "type_of_medicine" ||
            selectedOption === "tags" ||
            selectedOption === "brand") && (
            <div className={styles.time_options}>
              {intervalOfTime.map((item, index) => (
                <div
                  key={index}
                  className={
                    item.nameForRequest === selectedTime
                      ? styles.active_option
                      : styles.option
                  }
                  onClick={() => {
                    setSelectedTime(item.nameForRequest);
                  }}
                >
                  <h1>{item.name}</h1>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export { TrendAnalytics };
