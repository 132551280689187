import React, { useState, useEffect } from "react";
import styles from "./Shop.module.scss";
import { vh, vw } from "plugins/convertPXtoVHVW";
import star from "assets/img/star_12.svg";
import search_icon from "assets/img/search.svg";
import { axiosMyShop } from "plugins/axios";
import { ModalTwoButtons, Tabs, Input } from "components";
import { useOutsideClick } from "hooks";
import { useNavigate } from "react-router-dom";
import { axiosSeller } from "plugins/axios";

const TAB_STANDART = { name: "standart", title: "Отзывы без ответов" };
const TAB_ARCHIVE = { name: "archive", title: "Архив" };
const TAB_PRODUCTS = [TAB_STANDART, TAB_ARCHIVE];

const ShopReviews = () => {
  const navigate = useNavigate();

  const [pinnedReviews, setPinnedReviews] = useState([]);
  const [products, setProducts] = useState([]);
  const [currentTab, setCurrentTab] = useState(TAB_STANDART.name);
  const [tabs, setTabs] = useState(TAB_PRODUCTS);
  const [categories, setCategories] = useState([]);

  const [idForAnswer, setIdForAnswer] = useState(-1);

  const [wasLastPinned, setWasLastPinned] = useState(false);
  const [currentPinnedPage, setCurrentPinnedPage] = useState(1);
  const [nextPinnedPage, setNextPinnedPage] = useState(2);
  const [wasLastProducts, setWasLastProducts] = useState(false);
  const [currentProductsPage, setCurrentProductsPage] = useState(1);
  const [nextProductsPage, setNextProductsPage] = useState(2);

  const [textAnswer, setTextAnswer] = useState("");

  const [searchText, setSearchText] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const onScrollPinned = (event) => {
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight >=
      event.target.scrollHeight - 10;
    if (scrollBottom && currentPinnedPage < nextPinnedPage) {
      setCurrentPinnedPage(currentPinnedPage + 1);
    }
  };
  const onScrollProducts = (event) => {
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight >=
      event.target.scrollHeight - 10;
    if (scrollBottom && currentProductsPage < nextProductsPage) {
      setCurrentProductsPage(currentProductsPage + 1);
    }
  };

  useEffect(() => {
    const getCategories = async () => {
      let { categories: resCategories } = await axiosMyShop.getMyGoodsFilter();
      if (resCategories && resCategories.length) {
        setCategories(resCategories);
      }
    };
    getCategories();
  }, []);

  useEffect(() => {
    const getProducts = async () => {
      await axiosSeller.getSellerNotificationsCount();
      let resProducts = await axiosMyShop.getReviewList(
        currentProductsPage,
        `type=${currentTab}&query=${searchText}&category=${selectedCategory}`
      );
      if (resProducts?.results) {
        setTabs(
          tabs.map((tab) => ({
            ...tab,
            title:
              tab.name === TAB_STANDART.name
                ? `${TAB_STANDART.title} ${resProducts.results.standart_count}`
                : `${TAB_ARCHIVE.title} ${resProducts.results.archive_count}`,
          }))
        );

        setProducts(
          currentProductsPage === 1
            ? resProducts.results?.data || []
            : [...products, ...resProducts.results?.data]
        );

        setNextProductsPage(
          !resProducts.next ? currentProductsPage : currentProductsPage + 1
        );
        setWasLastProducts(!resProducts.next);
      }
    };
    if (!wasLastProducts) {
      getProducts();
      setIsSearch(false);
    }
  }, [currentProductsPage, wasLastProducts, currentTab, isSearch]);

  useEffect(() => {
    const getPinnedReviews = async () => {
      await axiosSeller.getSellerNotificationsCount();
      let resPinned = await axiosMyShop.getPinnedReviews(currentPinnedPage);
      if (resPinned?.results) {
        setPinnedReviews(
          currentPinnedPage === 1
            ? resPinned.results
            : [...pinnedReviews, ...resPinned.results]
        );

        setNextPinnedPage(
          !resPinned.next ? currentPinnedPage : currentPinnedPage + 1
        );
        setWasLastPinned(!resPinned.next);
      }
    };

    if (!wasLastPinned) {
      getPinnedReviews();
    }
  }, [wasLastPinned, currentPinnedPage]);

  const answerReview = async (id) => {
    if (textAnswer.trim().length) {
      let resAnswerReview = await axiosMyShop.answerReview(id, textAnswer);
      if (!resAnswerReview) {
        let resUnpinReview = await axiosMyShop.pinReview(id);
        if (!resUnpinReview) {
          setPinnedReviews(pinnedReviews.filter((review) => review.id !== id));
          search();
        }
      }
    }
  };
  const search = () => {
    setIsSearch(true);
    setWasLastProducts(false);
    setCurrentProductsPage(1);
    setNextProductsPage(2);
  };

  return (
    <div className={styles.reviews__cont}>
      <div className={styles.reviews__header}>
        <div className={styles.reviews__header__title}>Отзывы</div>
        <div className={styles.reviews__header__description}>
          Здесь вы можете увидеть все отзывы на ваш товар. Вы можете ответить на
          них в течение 30 дней, после чего они переходят в “Архив”.
        </div>
      </div>
      <div className={styles.reviews__filters}>
        <CustomSelectCategory
          label="Выбрать категорию"
          value={selectedCategory || -1}
          name="category"
          categories={categories}
          change={(id) => {
            setSelectedCategory(id);
            search();
          }}
        />
        <Input
          light
          placeholder="Введите артикул или название товара"
          value={searchText}
          fontColor="#BFC4C3"
          height={vh(42)}
          width={vw(433)}
          inputStyles={{
            padding: `${vh(12)} ${vw(13.5)}`,
            height: "100%",
            width: "100%",
            boxSizing: "border-box",
            fontSize: vh(14),
          }}
          prependStyles={{ height: "100%", boxSizing: "border-box" }}
          prepend={
            <img
              src={search_icon}
              style={{ height: vh(10.67) }}
              alt="search"
              onClick={search}
            />
          }
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              search();
            }
          }}
        />
      </div>
      <div className={styles.reviews__pinned}>
        <div className={styles.reviews__pinned__title}>Закрепленные отзывы</div>
        <div className={styles.reviews__pinned__list} onScroll={onScrollPinned}>
          {pinnedReviews.length ? (
            pinnedReviews.map((review) => (
              <div key={review.id} className={styles.reviews__pinned__review}>
                <div className={styles.reviews__pinned__review__date}>
                  {review.creation_date}
                </div>
                <div className={styles.reviews__pinned__review__mark}>
                  <img
                    src={star}
                    width={vh(16)}
                    height={vh(16)}
                    alt="Оценка "
                  />
                  {review.mark}
                </div>
                <div className={styles.reviews__pinned__review__info}>
                  <div className={styles.reviews__pinned__review__info__header}>
                    <div
                      className={styles.reviews__pinned__review__info__username}
                    >
                      {review.username}
                    </div>
                    <div
                      className={styles.reviews__pinned__review__info__answer}
                      onClick={() => {
                        setTextAnswer("");
                        setIdForAnswer(review.id);
                      }}
                    >
                      Ответить
                    </div>
                  </div>
                  <div
                    className={styles.reviews__pinned__review__info__comment}
                  >
                    {review.comment}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.reviews__pinned__empty}>
              У вас нет закрепленных отзывов
            </div>
          )}
        </div>
      </div>
      <div className={styles.reviews__products}>
        <div className={styles.reviews__products__tabs}>
          <Tabs
            tabs={tabs}
            currentTab={currentTab}
            changeTab={(name) => {
              setCurrentTab(name);
              search();
            }}
          />
        </div>
        {products.length ? (
          <div className={styles.reviews__table} onScroll={onScrollProducts}>
            <div
              className={`${styles.reviews__table__row} ${styles.reviews__table__header}`}
            >
              <div>Название</div>
              <div style={{ textAlign: "center" }}>Фото</div>
              <div>Артикул</div>
              <div>Бренд</div>
              <div>Дата </div>
              <div>Рейтинг товара</div>
              <div>Количество отзывов</div>
            </div>
            {products.map((product) => (
              <div key={product.id} className={styles.reviews__table__row}>
                <div
                  className={styles.reviews__table__row__name}
                  title={product.name}
                >
                  {product.name}
                </div>
                <div
                  className={styles.reviews__table__row__image}
                  style={{ textAlign: "center" }}
                >
                  <img
                    src={
                      product?.pic_urls?.length
                        ? product.pic_urls[0]?.pic_url || ""
                        : ""
                    }
                    alt={product.name}
                    width="102px"
                    height="auto"
                  />
                </div>
                <div
                  className={styles.reviews__table__row__vendor}
                  title={product.vendor_code}
                >
                  {product.vendor_code}
                </div>
                <div
                  className={styles.reviews__table__row__brand}
                  title={product.brand}
                >
                  {product.brand}
                </div>
                <div className={styles.reviews__table__row__date}>
                  {product.creation_date}
                </div>
                <div className={styles.reviews__table__row__mark}>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={vh(14)}
                      height={vh(14)}
                      viewBox="0 0 14 14"
                      fill="none"
                      style={{ marginRight: vw(7) }}
                    >
                      <path
                        d="M6.57576 0.505867C6.73406 0.130533 7.26594 0.130534 7.42424 0.505868L9.02676 4.30541C9.09322 4.46298 9.24154 4.57074 9.41193 4.58525L13.5207 4.93522C13.9266 4.96979 14.091 5.47563 13.7829 5.74217L10.6645 8.44038C10.5352 8.55228 10.4786 8.72663 10.5174 8.89317L11.4543 12.909C11.5468 13.3057 11.1165 13.6183 10.7678 13.4077L7.23805 11.2758C7.09166 11.1874 6.90834 11.1874 6.76195 11.2758L3.23217 13.4077C2.88348 13.6183 2.45319 13.3057 2.54573 12.909L3.48258 8.89317C3.52143 8.72663 3.46478 8.55228 3.33546 8.44038L0.217086 5.74217C-0.0909591 5.47563 0.0734003 4.96979 0.479283 4.93522L4.58807 4.58525C4.75847 4.57074 4.90678 4.46298 4.97324 4.30541L6.57576 0.505867Z"
                        fill="#DC7413"
                      />
                    </svg>
                    {product?.product_mark}
                  </div>
                </div>
                <div className={styles.reviews__table__row__reviews}>
                  <div>{product?.review_count || 0}</div>
                  <div
                    onClick={() =>
                      navigate(
                        `/seller/shop/reviews/${product.id}?type=${currentTab}`
                      )
                    }
                  >
                    Смотреть все
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.reviews__table__empty}>Нет товаров</div>
        )}
      </div>
      {idForAnswer > -1 && (
        <ModalTwoButtons
          title="Ваш ответ"
          description={
            <textarea
              className={styles.reviews__textarea}
              placeholder="Введите сообщение"
              value={textAnswer}
              onChange={(e) => setTextAnswer(e.target.value)}
            />
          }
          button={{
            title: "Ответить",
            action: () => {
              setIdForAnswer(-1);
              answerReview(idForAnswer);
            },
          }}
          width={vw(874)}
          buttonStyle={{
            width: "100%",
            padding: `${vh(16)} ${vw(32)}`,
            fontSize: vh(18),
            lineHeight: vh(24),
          }}
          buttonClose={{ title: "Закрыть" }}
          close={() => {
            setIdForAnswer(-1);
            setTextAnswer("");
          }}
        />
      )}
    </div>
  );
};

const CustomSelectCategory = ({
  label = "",
  value = "",
  name = "",
  categories = [],
  change,
}) => {
  const [openSelect, setOpenSelect] = useState(false);
  const refSelect = useOutsideClick(() => setOpenSelect(false));

  const changeSelect = (id) => {
    setOpenSelect(false);
    change(id);
  };
  return (
    <div className={styles.custom_input}>
      <div ref={refSelect} name={name} className={styles.custom_input__select}>
        <ButtonSelect
          title={
            categories.find((category) => category.id === value)?.name || label
          }
          openSelect={openSelect}
          click={() => setOpenSelect(!openSelect)}
        />
        <ul
          className={`${styles.categories} ${openSelect ? styles.open : null}`}
        >
          <div>
            {categories.map((category) => (
              <li
                key={category.id}
                onClick={() => {
                  if (value === category.id) {
                    changeSelect("");
                  } else {
                    changeSelect(category.id);
                  }
                }}
              >
                <span className={value === category.id ? styles.select : null}>
                  {category.name}
                </span>
              </li>
            ))}
          </div>
        </ul>
      </div>
    </div>
  );
};

const ButtonSelect = ({ title = "", openSelect = false, click = () => {} }) => {
  return (
    <button className={openSelect ? styles.open : null} onClick={click}>
      <span>{title}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={vh(12)}
        height={vh(13)}
        viewBox="0 0 12 13"
        fill="none"
      >
        <path
          d="M1 4.5C1 4.5 4.04201 7.00735 5.76697 8.32346C5.90441 8.42833 6.09483 8.42413 6.22983 8.31614C8.04139 6.86689 9.12532 5.99974 11 4.5"
          stroke="#5F6B69"
          strokeWidth="0.61828"
          strokeLinecap="round"
        />
      </svg>
    </button>
  );
};
export { ShopReviews };
