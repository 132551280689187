import styles from "./FinancialAnalytics.module.scss";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { axiosSeller } from "plugins/axios";
import { Button } from "components";
import details from "assets/img/details.svg";

const TABS = [
  { name: "week", title: "Неделя" },
  { name: "month", title: "Месяц" },
  { name: "quarter", title: "3 месяца" },
  { name: "half", title: "6 месяцев" },
  { name: "year", title: "Год" },
];

const FinancialAnalytics = () => {
  const [sorting, setSorting] = useState("week");
  const [summary, setSummary] = useState({
    overall: 0,
    saved: 0,
    paid: 0,
  });

  const labels = [
    "Удержано",
    "Выплачено",
    //  "Штрафы",
    "Всего получено",
  ];

  const data = {
    labels,
    datasets: [
      {
        maxBarThickness: "90",
        data: [
          summary.saved,
          summary.paid,
          // summary.overall,
          summary.overall,
        ],
        backgroundColor: [
          "hsl(" + Math.random() * 360 + ", 100%, 95%)",
          "hsl(" + Math.random() * 360 + ", 100%, 95%)",
          "hsl(" + Math.random() * 360 + ", 100%, 95%)",
          // "hsl(" + Math.random() * 360 + ", 100%, 95%)",
        ],
        borderRadius: 9,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: { display: false },
        border: { color: "#EAF8E7", width: 2 },
      },
      y: { display: false },
    },
    responsive: true,
    layout: { padding: { top: 30 } },
    plugins: {
      legend: { display: false },
      datalabels: {
        color: "#20B900",
        weight: "600",
        anchor: "end",
        align: "start",
        offset: -25,
        formatter: function (value, context) {
          return `${value} руб`;
        },
      },
    },
  };

  const exportToExel = async () => {
    let res = await axiosSeller.getFinancialExcel(sorting);
    const blob = new Blob([res], {
      type: "application/vnd.openxmlformats-officedocument.speadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download =
      "Финансовая отчетность " +
      TABS.find((el) => el.name === sorting).title +
      ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getMainAnalytics = async () => {
    let resp = await axiosSeller.getMain(sorting);
    setSummary({
      overall: resp.summary?.overall || 0,
      saved: resp.summary?.saved || 0,
      paid: resp.summary?.paid || 0,
    });
  };

  useEffect(() => {
    getMainAnalytics();
  }, [sorting]);

  return (
    <section className={styles.container}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1 className={styles.heading}>Финансовая отчетность</h1>
        <Button
          text="Скачать файл"
          padding="1vh 1vw"
          bgColor="#EAF8E7"
          color="#5F6B69"
          prepend={
            <img
              src={details}
              alt="Скачать файл"
              style={{ aspectRatio: 1, height: "2vh", margin: "0" }}
            />
          }
          click={exportToExel}
        />
      </div>
      <div className={styles.periods}>
        {TABS.map((per, index) => (
          <span
            key={index}
            className={`${styles.span} ${
              sorting === per.name ? styles.selected : ""
            }`}
            onClick={() => setSorting(per.name)}
          >
            {per.title}
          </span>
        ))}
      </div>
      <div style={{ height: "55vh" }}>
        <Bar options={options} data={data} />
      </div>
    </section>
  );
};

export { FinancialAnalytics };
