import React, { useState, useEffect } from "react";
import styles from "./ServiceAnalytics.module.scss";
import { useParams } from "react-router-dom";
import { axiosSeller } from "plugins/axios";
import { checkError } from "plugins/checkError";
import { vh, vw } from "plugins/convertPXtoVHVW";

const TIME_PERIOD = [
  { period: "week", title: "Неделя" },
  { period: "month", title: "Месяц" },
  { period: "quarter", title: "3 месяца" },
  { period: "half", title: "6 месяцев" },
  { period: "year", title: "Год" },
];

const ServiceAnalytics = () => {
  const { id: centerId } = useParams();
  const [period, setPeriod] = useState(TIME_PERIOD[0].period);
  const [center, setCenter] = useState({});
  const [centerAnalytics, setCenterAnalytics] = useState({});
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const getCenter = async () => {
      let resCenter = await axiosSeller.getRawService(centerId);
      let { isError, messageError } = checkError(resCenter);
      if (isError) {
        setCenter({});
        setCenterAnalytics({});
        return;
      }
      setCenter(resCenter);
    };

    getCenter();
  }, []);

  useEffect(() => {
    const getServiceAnalytics = async () => {
      let resService = await axiosSeller.getServiceAnalytics(
        centerId,
        `period=${period}`
      );
      let { isError, messageError } = checkError(resService);
      if (isError) {
        setCenter({});
        setCenterAnalytics({});
        return;
      }
      setCenterAnalytics(resService);
      setCities(
        resService?.counts_by_city?.filter((city) => city.city_percent)
      );
    };

    getServiceAnalytics();
  }, [period]);

  const exportToExel = async () => {
    let res = await axiosSeller.getServiceAnalyticsExcel(
      centerId,
      `period=${period}`
    );

    const blob = new Blob([res], {
      type: "application/vnd.openxmlformats-officedocument.speadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Аналитика по услугам " + center?.name + ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={styles.service_analytics__cont}>
      <div className={styles.service_analytics__header}>
        <div className={styles.service_analytics__header__title}>
          Аналитика по услугам
        </div>
        <div className={styles.service_analytics__header__periods}>
          {TIME_PERIOD.map((p, index) => (
            <span
              key={index}
              className={
                period === p.period
                  ? styles.service_analytics__header__periods__selected
                  : ""
              }
              onClick={() => {
                setPeriod(p.period);
              }}
            >
              {p.title}
            </span>
          ))}
        </div>
      </div>
      <div className={styles.service_analytics__analytics}>
        <div className={styles.service_analytics__analytics__header}>
          <div className={styles.service_analytics__analytics__header__title}>
            {center?.name}
          </div>
          <div
            className={styles.service_analytics__analytics__header__download}
            onClick={exportToExel}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={vh(17)}
              height={vh(16)}
              viewBox="0 0 17 16"
              fill="none"
              style={{ marginRight: vw(6) }}
            >
              <path
                d="M14.7087 2.63774H13.5876V1.51664C13.5876 1.40707 13.5441 1.30199 13.4666 1.22452C13.3891 1.14704 13.284 1.10352 13.1745 1.10352H2.29203C2.18246 1.10352 2.07738 1.14704 1.99991 1.22452C1.92243 1.30199 1.87891 1.40707 1.87891 1.51664V13.0241C1.87891 13.1337 1.92243 13.2388 1.99991 13.3163C2.07738 13.3937 2.18246 13.4373 2.29203 13.4373H3.41314V14.5568C3.41294 14.611 3.42343 14.6647 3.44403 14.7148C3.46462 14.7649 3.49491 14.8105 3.53315 14.8489C3.57138 14.8872 3.61683 14.9177 3.66686 14.9385C3.7169 14.9593 3.77054 14.97 3.82472 14.97H14.7087C14.7629 14.97 14.8165 14.9593 14.8666 14.9385C14.9166 14.9177 14.962 14.8872 15.0003 14.8489C15.0385 14.8105 15.0688 14.7649 15.0894 14.7148C15.11 14.6647 15.1205 14.611 15.1203 14.5568V3.05087C15.1205 2.99669 15.11 2.943 15.0894 2.89289C15.0688 2.84278 15.0385 2.79722 15.0003 2.75884C14.962 2.72046 14.9166 2.69 14.8666 2.66922C14.8165 2.64844 14.7629 2.63774 14.7087 2.63774ZM3.53293 7.23583C3.45626 7.31361 3.41324 7.41841 3.41314 7.52763V12.611H2.70515V1.92976H12.7629V2.63774H8.30916C8.25491 2.63758 8.20115 2.64822 8.15105 2.66905C8.10096 2.68988 8.05551 2.72048 8.01736 2.75907L3.53293 7.23583ZM7.8899 4.04604V7.11758H4.81837L7.8899 4.04604ZM14.2956 14.1452H4.23785V7.94075H8.30302C8.41259 7.94075 8.51766 7.89722 8.59514 7.81975C8.67261 7.74227 8.71614 7.6372 8.71614 7.52763V3.46553H14.2956V14.1452Z"
                fill="#5F6B69"
              />
            </svg>
            Скачать
          </div>
        </div>
        <div className={styles.service_analytics__analytics__general}>
          <div className={styles.service_analytics__analytics__general__center}>
            Количество переходов на страницу центра:
            <span>{centerAnalytics?.visit_count || 0}</span>
          </div>
          <div
            className={styles.service_analytics__analytics__general__website}
          >
            Количество переходов на сайт :
            <span>{centerAnalytics?.redirect_count || 0}</span>
          </div>
          <div>
            География спроса:
            {cities?.length ? (
              <div
                className={
                  styles.service_analytics__analytics__general__statistics
                }
              >
                {cities.map((city, index) => (
                  <div key={index} className={styles.custom_bar}>
                    <div className={styles.custom_bar__title}>
                      {city.city_name}
                    </div>
                    <div className={styles.custom_bar__line}>
                      <span
                        style={{
                          width: `${city.city_percent}%`,
                          backgroundColor:
                            "hsl(" + Math.random() * 360 + ", 100%, 95%)",
                        }}
                        title={`${city.city_name} ${city.city_percent}%`}
                      />
                      <span>{city.city_percent}%</span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <span>Нет данных в заданный период</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { ServiceAnalytics };
