import step_1 from "../../assets/img/step_1.png";
import step_2 from "../../assets/img/step_2.png";
import step_1_2 from "../../assets/img/step_1_2.png";
import step_2_2 from "../../assets/img/step_2_2.png";
import vector_25 from "../../assets/img/vector_25.svg";
import main_photo from "../../assets/img/main_photo.svg";
import small_photo from "../../assets/img/small_photo.svg";
import arrow_2 from "../../assets/img/arrow_2.svg";
import arrow_green from "../../assets/img/arrow_green.svg";
import birdie from "../../assets/img/birdie.svg";

import styles from "./CreateService.module.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ServiceInfo } from "components";
import { axiosSeller } from "plugins/axios";

const CreateService = () => {
  const navigate = useNavigate();
  const [medicine, setMedicine] = useState([]);
  const [cities, setСities] = useState([]);
  const [typesOfService, setTypesOfService] = useState([]);
  const [preview, setPreview] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openCategory, setOpenCategory] = useState([]);
  const [countServices, setCountServices] = useState(1);

  const [openSelect, setOpenSelect] = useState({
    openMed: false,
    openСities: false,
    openTypesOfService: false,
  });

  const [serviceFields, setServiceFields] = useState({
    name: "",
    main_site: "",
    type_of_medicine: [],
    type_of_medicine_names: [],
    description: "",
    city: [],
    city_names: [],
    phone_number: "",
    address: "",
    whatsapp_phone: "",
    telegram_phone: "",
    types_of_service: [],
    types_of_service_names: [],
  });

  const [pics, setPics] = useState([
    {
      id: 1,
      pic: main_photo,
      nameProductFields: "pic_1",
      picProductFields: "",
    },
    {
      id: 2,
      pic: small_photo,
      nameProductFields: "pic_2",
      picProductFields: "",
    },
    {
      id: 3,
      pic: small_photo,
      nameProductFields: "pic_3",
      picProductFields: "",
    },
    {
      id: 4,
      pic: small_photo,
      nameProductFields: "pic_4",
      picProductFields: "",
    },
    {
      id: 5,
      pic: small_photo,
      nameProductFields: "pic_5",
      picProductFields: "",
    },
    {
      id: 6,
      pic: small_photo,
      nameProductFields: "pic_6",
      picProductFields: "",
    },
    {
      id: 7,
      pic: small_photo,
      nameProductFields: "pic_7",
      picProductFields: "",
    },
    {
      id: 8,
      pic: small_photo,
      nameProductFields: "pic_8",
      picProductFields: "",
    },
    {
      id: 9,
      pic: small_photo,
      nameProductFields: "pic_9",
      picProductFields: "",
    },
  ]);

  const [services, setServices] = useState([
    {
      service_name: "service_name_1",
      service_name_value: "",
      service_desc: "service_desc_1",
      service_desc_value: "",
      service_price_from: "service_price_from_1",
      service_price_from_value: "",
      service_price_to: "service_price_to_1",
      service_price_to_value: "",
    },
  ]);

  const addService = () => {
    let serv = {
      service_name: "service_name_" + (services.length + 1),
      service_name_value: "",
      service_desc: "service_desc_" + (services.length + 1),
      service_desc_value: "",
      service_price_from: "service_price_from_" + (services.length + 1),
      service_price_from_value: "",
      service_price_to: "service_price_to_" + (services.length + 1),
      service_price_to_value: "",
    };
    setServices([...services, serv]);
  };

  const getMedicineCreateService = async () => {
    await axiosSeller.getSellerNotificationsCount();
    let result = await axiosSeller.getMedicineCreateService();
    setMedicine(result.types_of_medicine || []);
    setСities(result.cities || []);
    setTypesOfService(result.types_of_service || []);
  };

  const checkProperties = (obj) => {
    for (let key in obj) {
      if (obj[key] === null || obj[key] == "" || obj[key] == []) return key;
    }
    return false;
  };

  const createService = async () => {
    let clone = Object.assign({}, serviceFields);
    delete clone.types_of_service_names;
    delete clone.type_of_medicine_names;
    delete clone.city_names;
    delete clone.newServiceFields;
    setErrorMessage("");
    if (checkProperties(clone)) {
      console.log("Заполните все поля." + checkProperties(clone));

      setErrorMessage("Заполните все поля.");
    } else {
      let result = await axiosSeller.createService(clone);

      if (result.status === 201) {
        navigate("/seller/shop/products");
      }
      if (result.status === 400) {
        if (result.data.Error === "Such service already exists") {
          setErrorMessage("Такая услуга уже существует.");
        }
        if (result.data.Error === "Necessary fields are not given") {
          setErrorMessage("Заполните все поля.");
        }
      }
    }
  };

  const AddImages = (Image) => {
    let i = 0;
    let newPics = [...pics];
    while (i < pics.length) {
      if (pics[i].picProductFields === "") {
        newPics[i].picProductFields = Image;
        setPics(newPics);
        setServiceFields({
          ...serviceFields,
          [newPics[i].nameProductFields]: Image,
        });
        break;
      }
      i++;
    }
  };

  const addOrRemoveServiceFields = (item, field, field_name) => {
    if (serviceFields[field].find((element) => element === item.pk)) {
      setServiceFields({
        ...serviceFields,
        [field]: serviceFields[field].filter((n) => {
          return n != item.pk;
        }),
        [field_name]: serviceFields[field_name].filter((n) => {
          return n != item.name;
        }),
      });
    } else {
      setServiceFields({
        ...serviceFields,
        [field]: [...serviceFields[field], item.pk],
        [field_name]: [...serviceFields[field_name], item.name],
      });
    }
  };

  const showCategories = (category, field, field_name) => {
    return (
      <div style={{ marginLeft: "4vh" }}>
        {category?.children?.length === 0 || !category.children ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            onClick={() => {
              addOrRemoveServiceFields(category.obj, field, field_name);
            }}
          >
            {serviceFields[field].find(
              (element) => element === category.obj.pk
            ) ? (
              <img
                src={birdie}
                alt="birdie"
                style={{ height: "1.5vh", paddingRight: "1.5vh" }}
              />
            ) : null}
            <h1
              style={
                serviceFields[field].find(
                  (element) => element === category.obj.pk
                )
                  ? { color: "#20b900" }
                  : null
              }
              className={styles.empty_category}
            >
              {category.obj.name}
            </h1>
          </div>
        ) : (
          <div>
            <div
              className={
                openCategory.find((element) => element === category.obj.pk)
                  ? styles.open_category
                  : styles.category
              }
              onClick={() => {
                if (
                  openCategory.find((element) => element === category.obj.pk)
                ) {
                  setOpenCategory(
                    openCategory.filter((n) => {
                      return n != category.obj.pk;
                    })
                  );
                } else {
                  setOpenCategory([...openCategory, category.obj.pk]);
                }
              }}
            >
              <h1>{category.obj.name}</h1>
              {openCategory.find((element) => element === category.obj.pk) ? (
                <img
                  src={arrow_green}
                  alt="arrow_green"
                  style={{ height: "0.8vh", padding: "0 2vh" }}
                />
              ) : (
                <img
                  src={arrow_2}
                  alt="arrow_2"
                  style={{ height: "1.5vh", paddingLeft: "1vh" }}
                />
              )}
            </div>
            {openCategory.find((element) => element === category.obj.pk) && (
              <div className={styles.part_categories}>
                {category.children.map((item, index) =>
                  item?.children?.length === 0 || !category.children ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: "4vh",
                      }}
                      key={index}
                    >
                      {serviceFields[field].find(
                        (element) => element === item.obj.pk
                      ) ? (
                        <img
                          src={birdie}
                          alt="birdie"
                          style={{ height: "1.5vh", paddingRight: "1.5vh" }}
                        />
                      ) : null}
                      <h1
                        className={styles.empty_category}
                        style={
                          serviceFields[field].find(
                            (element) => element === item.obj.pk
                          )
                            ? { color: "#20b900" }
                            : null
                        }
                        onClick={() => {
                          addOrRemoveServiceFields(item.obj, field, field_name);
                        }}
                      >
                        {item.obj.name}
                      </h1>
                    </div>
                  ) : (
                    showCategories(item, field, field_name)
                  )
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const addNewService = () => {
    services.map((item) => {
      if (
        item.service_name_value !== "" &&
        item.service_desc_value !== "" &&
        item.service_price_from_value !== "" &&
        item.service_price_to_value !== ""
      ) {
        let newServiceFields = serviceFields;
        newServiceFields[item.service_name] = item.service_name_value;
        newServiceFields[item.service_desc] = item.service_desc_value;
        newServiceFields[item.service_price_from] =
          item.service_price_from_value;
        newServiceFields[item.service_price_to] = item.service_price_to_value;
        setServiceFields({
          ...serviceFields,
          newServiceFields,
        });
      }
    });
  };

  useEffect(() => {
    getMedicineCreateService();
    addNewService();
  }, [serviceFields.type_of_medicine]);
  return (
    <div className={styles.container}>
      <Helmet title="Добавить Услугу">
        <link rel="canonical" href="/seller/createservice" />
        <meta
          name="description"
          content='Форма добавления услуги на сайт "Shalfey".'
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div>
        {preview ? (
          <h1 className={styles.heading}>Предварительный просмотр</h1>
        ) : (
          <h1 className={styles.heading}>Создание услуги</h1>
        )}
      </div>
      {preview ? (
        <div className={styles.steps}>
          <div>
            <img src={step_1} alt="Шаг1" />
            <h3 style={{ color: "#CBCBCB", marginLeft: "2vh" }}>
              Информация о услуге
            </h3>
          </div>
          <div style={{ margin: "0 5vh" }}>
            <img src={step_2_2} alt="Шаг2" />
            <h3 style={{ marginLeft: "2vh" }}>Предварительный просмотр</h3>
          </div>
        </div>
      ) : (
        <div className={styles.steps}>
          <div>
            <img src={step_1_2} alt="Шаг1" />
            <h3 style={{ marginLeft: "2vh" }}>Информация о услуге</h3>
          </div>
          <div style={{ margin: "0 5vh" }}>
            <img src={step_2} alt="Шаг2" />
            <h3 style={{ color: "#CBCBCB", marginLeft: "2vh" }}>
              Предварительный просмотр
            </h3>
          </div>
        </div>
      )}
      {!preview ? (
        <div style={{ display: "flex" }}>
          <div className={styles.part}>
            <div>
              <div className={styles.title}>
                <h3>Основная информация</h3>
              </div>
              <div className={styles.content}>
                <input
                  value={serviceFields.name}
                  placeholder="Название"
                  onChange={(event) => {
                    setServiceFields({
                      ...serviceFields,
                      name: event.target.value,
                    });
                  }}
                />
                <input
                  value={serviceFields.main_site}
                  placeholder="Ссылка на ваш сайт"
                  onChange={(event) => {
                    setServiceFields({
                      ...serviceFields,
                      main_site: event.target.value,
                    });
                  }}
                />
                <input
                  value={serviceFields.description}
                  placeholder="Описание"
                  onChange={(event) => {
                    setServiceFields({
                      ...serviceFields,
                      description: event.target.value,
                    });
                  }}
                />

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <div
                      className={styles.medicine}
                      style={{ width: "19vw", marginRight: "2vh" }}
                      onClick={() => {
                        setOpenSelect({
                          openMed: !openSelect.openMed,
                        });
                      }}
                    >
                      <p>Вид медицины</p>
                      <img src={vector_25} alt="vector_25" />
                    </div>
                    {openSelect.openMed && (
                      <div className={styles.types_of_medicine}>
                        {medicine?.map((item, index) => (
                          <div
                            className={styles.type}
                            key={index}
                            onClick={() => {
                              setServiceFields({
                                ...serviceFields,
                                type_of_medicine: item.id,
                                type_of_medicine_names: item.name,
                              });
                            }}
                          >
                            <p
                              className={
                                serviceFields.type_of_medicine === item.id &&
                                styles.open_medicine
                              }
                            >
                              {item.name}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      className={styles.medicine}
                      style={{ width: "19vw" }}
                      onClick={() => {
                        setOpenSelect({
                          openTypesOfService: !openSelect.openTypesOfService,
                        });
                      }}
                    >
                      <p>Виды услуг</p>
                      <img src={vector_25} alt="vector_25" />
                    </div>
                    {openSelect.openTypesOfService && (
                      <div>
                        {typesOfService?.map((item, index) =>
                          showCategories(
                            item,
                            "types_of_service",
                            "types_of_service_names"
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.title_pic}>
                <h1>Фото</h1>
                <label
                  for="picfile"
                  class="label"
                  style={{ color: "#20B900", cursor: "pointer" }}
                >
                  Добавить фото
                </label>
                <input
                  type="file"
                  class="my"
                  id="picfile"
                  name="picfile"
                  onChange={(event) => {
                    AddImages(event.target.files[0]);
                  }}
                />
              </div>
              <div className={styles.pics}>
                <div className={styles.main_photo}>
                  <img
                    src={
                      pics[0].picProductFields === ""
                        ? pics[0].pic
                        : URL.createObjectURL(pics[0].picProductFields)
                    }
                    alt={pics[0].nameProductFields}
                  />
                </div>
                <div className={styles.small_photo}>
                  {pics.slice(1).map((item, index) => (
                    <img
                      key={index}
                      src={
                        item.picProductFields === ""
                          ? item.pic
                          : URL.createObjectURL(item.picProductFields)
                      }
                      alt={item.nameProductFields}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.part}>
            <div>
              <div className={styles.title}>
                <h3>Контакты</h3>
              </div>
              <div className={styles.content}>
                <div
                  className={styles.medicine}
                  onClick={() => {
                    setOpenSelect({
                      openСities: !openSelect.openСities,
                    });
                  }}
                >
                  <p>Город</p>
                  <img src={vector_25} alt="vector_25" />
                </div>
                {openSelect.openСities && (
                  <div className={styles.types_of_medicine}>
                    {cities?.map((item, index) => (
                      <div
                        className={styles.type}
                        key={index}
                        onClick={() => {
                          setServiceFields({
                            ...serviceFields,
                            city: item.id,
                            city_names: item.name,
                          });
                        }}
                      >
                        <p
                          className={
                            serviceFields.city === item.id &&
                            styles.open_medicine
                          }
                        >
                          {item.name}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                <input
                  value={serviceFields.address}
                  placeholder="Адрес"
                  onChange={(event) => {
                    setServiceFields({
                      ...serviceFields,
                      address: event.target.value,
                    });
                  }}
                />
                <input
                  value={serviceFields.phone_number}
                  placeholder="Контактный номер телефона"
                  onChange={(event) => {
                    setServiceFields({
                      ...serviceFields,
                      phone_number: event.target.value,
                    });
                  }}
                />
                <div>
                  <input
                    value={serviceFields.telegram_phone}
                    placeholder="Ссылка на Telegram"
                    style={{ width: "22vw" }}
                    onChange={(event) => {
                      setServiceFields({
                        ...serviceFields,
                        telegram_phone: event.target.value,
                      });
                    }}
                  />
                  <input
                    value={serviceFields.whatsapp_phone}
                    placeholder="Ссылка на What’s Up"
                    style={{ width: "22vw" }}
                    onChange={(event) => {
                      setServiceFields({
                        ...serviceFields,
                        whatsapp_phone: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className={styles.title}>
                <h3 style={{ margin: "8vh 0 2vh 0" }}>Услуги</h3>
              </div>
              <div className={styles.content}>
                <div className={styles.services}>
                  {[...Array(services.length)].map((x, i) => (
                    <div style={{ marginBottom: "5vh" }}>
                      <input
                        value={services[i].service_name_value}
                        placeholder="Название"
                        onChange={(event) => {
                          let newServices = [...services];
                          newServices[i].service_name_value =
                            event.target.value;
                          setServices(newServices);
                        }}
                      />
                      <input
                        value={services[i].service_desc_value}
                        placeholder="Описание"
                        onChange={(event) => {
                          let newServices = [...services];
                          newServices[i].service_desc_value =
                            event.target.value;
                          setServices(newServices);
                        }}
                      />
                      <p>Стоимость услуги</p>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h1>от</h1>
                        <input
                          value={services[i].service_price_from_value}
                          placeholder="Цена"
                          style={{ width: "9vw", margin: "0 1vh" }}
                          type="number"
                          onChange={(event) => {
                            let newServices = [...services];
                            newServices[i].service_price_from_value =
                              event.target.value;
                            setServices(newServices);
                          }}
                        />
                        <h1>до</h1>
                        <input
                          value={services[i].service_price_to_value}
                          placeholder="Цена"
                          style={{ width: "9vw", margin: "0 1vh" }}
                          type="number"
                          onChange={(event) => {
                            let newServices = [...services];
                            newServices[i].service_price_to_value =
                              event.target.value;
                            setServices(newServices);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                  <h2
                    onClick={() => {
                      addService();
                    }}
                  >
                    + Добавить услугу
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.result}>
          <div style={{ backgroundColor: "#FFFFFF", margin: "5vh 0" }}>
            <ServiceInfo
              service={{
                name: serviceFields.name,
                main_site: serviceFields.main_site,
                type_of_medicine: serviceFields.type_of_medicine_names,
                description: serviceFields.description,
                city: serviceFields.city_names,
                phone: serviceFields.phone_number,
                telegram_phone: serviceFields.telegram_phone,
                whatsapp_phone: serviceFields.whatsapp_phone,
                address: serviceFields.address,
                types_of_service: serviceFields.types_of_service_names,
                phone: serviceFields.phone_number,

                pic_urls: pics
                  .filter((item, index) => item.picProductFields !== "")
                  .map((item, index) => ({
                    pic_url: item.picProductFields,
                  })),
                services: services
                  .filter(
                    (item, index) =>
                      item.service_name_value !== "" &&
                      item.service_desc_value !== "" &&
                      item.service_price_from_value !== "" &&
                      item.service_price_to_value !== ""
                  )
                  .map((item, index) => ({
                    name: item.service_name_value,
                    description: item.service_desc_value,
                    price: `от ${item.service_price_from_value} руб до ${item.service_price_to_value} руб`,
                  })),
              }}
              create_service={true}
            />
          </div>
        </div>
      )}

      {preview ? (
        <div>
          {errorMessage && <p>{errorMessage}</p>}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "15px",
            }}
          >
            <button
              onClick={() => {
                setPreview(false);
              }}
              className={styles.cancel}
            >
              Назад
            </button>
            <button className={styles.further} onClick={createService}>
              Опубликовать
            </button>
          </div>
        </div>
      ) : (
        <div>
          {errorMessage && <p>{errorMessage}</p>}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "15px",
            }}
          >
            <button
              onClick={() => {
                navigate("/seller/shop/products");
              }}
              className={styles.cancel}
            >
              Отмена
            </button>

            <button
              onClick={() => {
                setPreview(true);
                addNewService();
              }}
              className={styles.further}
            >
              Далее
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export { CreateService };
