import { Button, Loader } from "components";
import styles from "./NewProfileInfo.module.scss";
import React, { useEffect, useState } from "react";

import profile from "assets/img/profile_gray.svg";
import avatar from "assets/img/avatar.png";
import item_sold from "assets/img/item_sold.svg";
import growth from "assets/img/growth.svg";
import Star_empty_gray from "assets/img/Star_empty_gray.svg";
import reviews from "assets/img/reviews.svg";

import { axiosSeller } from "plugins/axios";
import { checkError } from "plugins/checkError";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PROFILE } from "constants/url";

const SellerProfileInfo = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const imageArray = [
    { pic: item_sold, title: "Товаров продано" },
    { pic: Star_empty_gray, title: "Рейтинг продавца по отзывам" },
    { pic: reviews, title: "Отзывы покупателей" },
  ];
  const [user, setUser] = useState({});
  const [countsArray, setCountsArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [needAuth, setNeedAuth] = useState(true);
  const [errMessage, setErrMessage] = useState("");

  const getUser = async () => {
    setLoading(true);
    let res = await axiosSeller.getSellerInfo();
    if (res.response?.status === 403) {
      window.location.href = process.env.REACT_APP_USER_URL + PROFILE.url;
    }
    const { isError, messageError } = checkError(res);
    if (isError) {
      setErrMessage(messageError);
      setNeedAuth(true);
      setLoading(false);
      return;
    }
    setUser(res);
    setCountsArray([
      res?.product_sold || 0,
      res?.product_rating?.toFixed(1) || 0,
      res?.review_count || 0,
      res?.profit_money || 0,
      res?.profit_goods || 0,
    ]);
    setNeedAuth(false);
    setLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  return loading ? (
    <Loader bgColor="#20B900" align="center" />
  ) : needAuth ? (
    <div>{errMessage}</div>
  ) : (
    <section className={styles.container}>
      <img
        className={styles.avatar}
        src={user?.avatar_url || avatar}
        alt="Аварар"
      />
      <div className={styles.name}>{user.name}</div>
      {searchParams.get("tab") !== "update" ? (
        <Button
          text="Редактировать профиль"
          bgColor="#F8FBF9"
          color="#5F6B69"
          padding="1.5vh 2vw"
          margin="0 auto"
          click={() => navigate("/seller?tab=update")}
        />
      ) : (
        <div className={styles.main_tab} onClick={() => navigate("/seller")}>
          <p>
            <img src={profile} alt="Профиль" />
            Профиль
          </p>
        </div>
      )}
      <div className={styles.price}>{user.account_balance} руб</div>
      <div className={styles.updated}>
        обновлено в {user.last_update.slice(11, 16)}
      </div>
      <div className={styles["horizontal-line"]} />
      <div className={styles.percent}>
        <p>
          <img src={growth} alt="Заказы за неделю" />
          Заказы за неделю
        </p>
        <div>
          <p>
            <b>+{countsArray[4]}%</b>&nbsp;(шт.)
          </p>
          <p>
            <b>+{countsArray[3]}%</b>&nbsp;(руб.)
          </p>
        </div>
      </div>
      {imageArray.map((item, index) => (
        <div key={index} className={styles.tab}>
          <p>
            <img src={item.pic} alt={item.title} />
            {item.title}
          </p>
          <p>{countsArray[index]}</p>
        </div>
      ))}
    </section>
  );
};

export { SellerProfileInfo };
