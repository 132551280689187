import React, { useState, useEffect } from "react";
import styles from "./OrderDetails.module.scss";
import { axiosDeliveries } from "plugins/axios";
import { Button, ToggleSwitch } from "components";
import EmptyProduct from "assets/img/empty_product.png";

const OrderDetails = ({ order_number }) => {
  const [editItem, setEditItem] = useState({});
  const [isNotify, setIsNotify] = useState(false);

  const setOrderNotifyStatus = async (value) => {
    await axiosDeliveries.changeOrderNotifyStatus(editItem.order_number);
    setIsNotify(value);
  };

  const downloadDocs = async () => {
    let result = await axiosDeliveries.getOrderDocuments(order_number);
    result.forEach(async (element) => {
      let resp = await axiosDeliveries.downloadDoc(element.file_url);
      const blob = new Blob([resp], {
        type: "application/pdf",
      });
      let a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.setAttribute("download", element.name);
      a.click();
    });
  };

  const getDetailsItem = async () => {
    let result = await axiosDeliveries.getOrderDetails(order_number);
    setEditItem(result);
    setIsNotify(result.notify_user);
  };

  useEffect(() => {
    getDetailsItem();
  }, []);

  return (
    <>
      <div className={styles.heading_detail}>1. Информация о заказе</div>
      <div className={styles.line}>
        <p className={styles.label}>Номер заказа:&nbsp;</p>
        <p>{editItem.order_number}</p>
      </div>
      <div className={styles.line}>
        <p className={styles.label}>Дата и время заказа:&nbsp;</p>
        <p>{editItem.order_date}</p>
      </div>
      <div className={styles.line}>
        <p className={styles.label}>Статус заказа:&nbsp;</p>
        <p>{editItem.order_status}</p>
      </div>
      <div className={styles.line}>
        <p className={styles.label}>Сумма заказа:&nbsp;</p>
        <p>{editItem.order_sum}&nbsp;руб.</p>
      </div>
      <div className={styles.heading_detail}>2. Информация о покупателе</div>
      <div className={styles.line}>
        <p className={styles.label}>ФИО:&nbsp;</p>
        <p>{editItem.buyer_fio}</p>
      </div>
      <div className={styles.line}>
        <p className={styles.label}>Контактные данные:&nbsp;</p>
        <p>{editItem.buyer_contact_info}</p>
      </div>
      <div className={styles.line}>
        <p className={styles.label}>Адрес доставки:&nbsp;</p>
        <p>{editItem.buyer_address}</p>
      </div>
      <div className={styles.heading_detail}>3. Состав заказа</div>
      {editItem.content?.map((item, index) => (
        <div
          key={index}
          className={styles.line}
          style={{ justifyContent: "space-between" }}
        >
          <div className={styles.line}>
            <div className={styles.product} style={{ marginRight: "4vw" }}>
              <p className={styles.label}>
                {index + 1}. Арт. {item.product.vendor_code}
              </p>
              <p className={styles.one_line}>{item.product.name}</p>
              <div className={styles.price}>
                <p>{item.count} шт.</p>
                <p>{item.price} р.</p>
              </div>
            </div>
            <div className={styles.product}>
              <p className={styles.label}>Характеристики товара</p>
              <p>Срок годности: {item.product.storage_conditions}</p>
              <div className={styles.price}>
                <div className={styles.line}>
                  <p className={styles.label}>Вес:&nbsp;</p>
                  <p>{item.product.weight}&nbsp;гр.</p>
                </div>
                <div className={styles.line}>
                  <p className={styles.label}>&nbsp;Габариты:&nbsp;</p>
                  <p>{item.product.dimensions}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.product}>
            <img
              src={item.product.pic_urls[0]?.pic_url || EmptyProduct}
              alt={item.product.name}
            />
          </div>
        </div>
      ))}
      <div className={styles.heading_detail}>4. Информация о доставке</div>
      <div className={styles.line}>
        <p className={styles.label}>Способ доставки:&nbsp;</p>
        <p>{editItem.delivery_type}</p>
      </div>
      <div className={styles.line}>
        <p className={styles.label}>Логистическая компания:&nbsp;</p>
        <p>{editItem.delivery_logistic_type}</p>
      </div>
      <div className={styles.line}>
        <p className={styles.label}>Cтоимость доставки:&nbsp;</p>
        <p>{editItem.delivery_price}</p>
      </div>
      <div className={styles.line}>
        <p className={styles.label}>Информация об оплате:&nbsp;</p>
        <p>{editItem.delivery_payment_status}</p>
      </div>
      <div className={styles.line}>
        <p className={styles.label}>Трек-номер:&nbsp;</p>
        <p>{editItem.track_number}</p>
      </div>
      <div className={styles.heading_detail}>5. Дополнительная информация</div>
      <div className={styles.line}>
        <p className={styles.label}>Комментарий покупателя:&nbsp;</p>
        <p>{editItem.comment}</p>
      </div>
      <div className={styles.line}>
        <p className={styles.label}>История изменений статуса заказа:&nbsp;</p>
      </div>
      {editItem.delivery_history?.map((item, index) => (
        <div key={index} className={styles.line}>
          <p className={styles.label}>{item.change_date}&nbsp;</p>
          <p>{item.get_change_type_display}</p>
        </div>
      ))}
      <div className={styles.line} style={{ margin: "1vh 0 3vh" }}>
        <p className={styles.label}>Документы:&nbsp;</p>
        <Button
          text="Скачать документацию"
          bgColor="#F2F7F4"
          color="#20B900"
          padding="1vh 0.5vw"
          click={downloadDocs}
        />
      </div>
      <ToggleSwitch
        text="Уведомлять покупателя об изменениях статуса заказа"
        isSelected={isNotify}
        onChange={setOrderNotifyStatus}
      />
    </>
  );
};

export { OrderDetails };
