import { Button } from "components";
import styles from "./Instraction.module.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import * as links from "constants/url";
import step1 from "assets/img/step1.png";
import step2 from "assets/img/step2.png";
import step3 from "assets/img/step3.png";
import step4 from "assets/img/step4.png";
import { Helmet } from "react-helmet";

const Instraction = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <section className={styles.container}>
      <Helmet title="Инструкция для продавцов">
        <meta name="description" content="Shalfey : Инструкция для продавцов" />
        <link rel="canonical" href="/instraction" />
      </Helmet>
      <div className={styles.heading_block}>
        <h1 className={styles.heading}>Присоединяйтесь к нашей экосистеме</h1>
        <p>
          Присоединяйтесь к "Шалфей", чтобы расширить возможности вашего
          бизнеса. Получите доступ к&nbsp;Экосистеме для оптимального и
          эффективного расположения ваших товаров внутри системы. 
        </p>
        <p>
          Благодаря разработанной классификации по&nbsp;симптомам, показаниям и
          категориям, ваша продукция станет доступнее для большего числа
          заинтересованных покупателей
        </p>
        <Button
          text="Стать продавцом"
          margin="21px auto 21px"
          padding="16px 0"
          width={isMobile ? "100%" : "15vw"}
          click={() => navigate(links.BECOME_SELLER.url)}
        />
      </div>
      <div className={styles.step1}>
        <div className={styles.content}>
          <h5 className={styles.title}>ШАГ 1</h5>
          <h2>Создайте профиль в нашей экосистеме, указав:</h2>
          <p>-Название вашей компании;</p>
          <p>-Контактную информацию;</p>
          <p>-Информацию о вашей продукции;</p>
          <p>-Реквизиты для оплаты;</p>
        </div>
        <img src={step1} alt="ШАГ 1" />
      </div>
      <div className={styles.step2}>
        <img src={step2} alt="ШАГ 2" />
        <div className={styles.content} style={{ marginLeft: "5%" }}>
          <h5 className={styles.title}>ШАГ 2</h5>
          <h2>Загрузите товары</h2>
          <h3>
            Предложите своим потенциальным покупателям широкий ассортимент своих
            товаров для здоровья:
          </h3>
          <p>-Добавьте подробные описания и фотографии;</p>
          <p>-Укажите цены и наличие;</p>
          <p>-Выберите категории товаров;</p>
          <h3>Мы поможем вам:</h3>
          <p>-Сделать вашу продукцию привлекательной для&nbsp;покупателей;</p>
          <p>-Оптимизировать карточки товаров;</p>
          <p>-Продвигать вашу продукцию на маркетплейсе;</p>
          <h3>Увеличьте свои продажи уже сегодня!</h3>
        </div>
      </div>
      <div className={styles.step3}>
        <div className={styles.content} style={{ marginRight: "5%" }}>
          <h5 className={styles.title}>ШАГ 3</h5>
          <h2>Подключите кабинет логистики</h2>
          <h3>Мы поможем вам:</h3>
          <p>-Научим вас пользоваться модулем логистики от наших партнеров;</p>
          <p>-Выбрать оптимальные способы доставки;</p>
          <p>-Рассчитать стоимость доставки;</p>
        </div>
        <img src={step3} alt="ШАГ 3" />
      </div>
      <div className={styles.step4}>
        <img src={step4} alt="ШАГ 4" />
        <div className={styles.content} style={{ marginLeft: "5%" }}>
          <h5 className={styles.title}>ШАГ 4</h5>
          <h2>Начинайте продавать!</h2>
          <p>
            Все готово, чтобы вы могли начать продавать на нашем маркетплейсе.
          </p>
          <h3>Продвигайте свою продукцию:</h3>
          <p>-Используйте рекламные инструменты маркетплейса;</p>
          <p>-Участвуйте в акциях и распродажах;</p>
          <p>-Продвигайте свою продукцию в социальных сетях;</p>
          <h3>Мы поможем вам:</h3>
          <p>-Продвигать вашу продукцию;</p>
          <p>-Повысить узнаваемость вашего бренда;</p>
          <p>-Увеличить ваши продажи;</p>
          <Button
            text="Стать продавцом"
            margin="21px auto 10px"
            padding="16px 0"
            width={isMobile ? "100%" : "15vw"}
            click={() => navigate(links.BECOME_SELLER.url)}
          />
        </div>
      </div>
    </section>
  );
};

export { Instraction };
