import * as url from "../endpoints";
import { instance, config, configExcel, instanceEmpty } from "./createAxios";
import { removeToken } from "../token";

export const axiosDeliveries = {
  async getOrders(params) {
    try {
      const response = await instance.get(url.ORDERS_HANDLER + params, config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getWarehouses(params) {
    try {
      const response = await instance.get(url.GET_WAREHOUSES + params, config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error.response;
    }
  },
  async changeOrderStatus(id, status) {
    try {
      const response = await instance.put(
        url.CHANGE_ORDER_STATUS(id),
        { status },
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async createOrder(id, data) {
    try {
      const response = await instance.post(url.CREATE_ORDER(id), data, config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async cancelOrder(id) {
    try {
      const response = await instance.put(url.CANCEL_ORDER(id), {}, config);
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async addWarehouse(data) {
    try {
      let formData = new FormData();
      formData.append("address", data.address);
      formData.append("working_hours", data.working_hours);
      formData.append("shipping_hours", data.shipping_hours);
      if (data.photo) {
        formData.append("photo", data.photo, data.photo.name);
      }
      const response = await instance.post(url.ADD_WAREHOUSE, formData, config);
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error.response;
    }
  },
  async updateWarehouse(data) {
    try {
      let formData = new FormData();
      formData.append("address", data.address);
      formData.append("working_hours", data.working_hours.replace(/\s+/g, ""));
      formData.append(
        "shipping_hours",
        data.shipping_hours.replace(/\s+/g, "")
      );
      if (data.photo) {
        formData.append("photo", data.photo, data.photo.name);
        formData.append("old_photo", "");
      } else {
        formData.append("photo", "");
        formData.append("old_photo", data.pic_url);
      }
      const response = await instance.put(
        url.UPDATE_WAREHOUSE(data.id),
        formData,
        config
      );
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error.response;
    }
  },
  async deleteWarehouse(id) {
    try {
      const response = await instance.delete(url.DELETE_WAREHOUSE(id), config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getOrderDetails(id) {
    try {
      const response = await instance.get(url.GET_ORDER_DETAILS(id), config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getExelWarehouses() {
    try {
      const response = await instance.get(url.GET_EXEL_EXPORT, configExcel);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getCanceledOrders(params, page) {
    try {
      const response = await instance.post(
        url.GET_CANCELED_ORDERS + "page=" + page,
        params,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error.response;
    }
  },
  async getOrderDocuments(order_number) {
    try {
      const response = await instance.get(
        url.GET_ORDER_DOCUMENTS(order_number),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async uploadOrderDocuments(order_number, file) {
    try {
      let formData = new FormData();
      formData.append("file", file, file.name);
      const response = await instance.post(
        url.ADD_ORDER_DOCUMENT(order_number),
        formData,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async removeDoc(id) {
    try {
      const response = await instance.delete(
        url.REMOVE_ORDER_DOCUMENT(id),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async changeOrderNotifyStatus(order_number) {
    try {
      const response = await instance.put(
        url.CHANGE_NOTIFY_STATUS(order_number),
        {},
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async downloadDoc(file_url) {
    try {
      const response = await instanceEmpty.get(file_url);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getFilterParams() {
    try {
      const response = await instance.get(url.GET_FILTER_PARAMS, config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async sendMessageCanseledOrder(order_number, data) {
    try {
      const response = await instance.post(
        url.SEND_MESSAGE_CANCELED_ORDER(order_number),
        { text: data },
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getTrackingOrders(params, page) {
    try {
      const response = await instance.post(
        url.GET_TRACKING_ORDERS + "page=" + page,
        params,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error.response;
    }
  },
  async deleteReturnProduct(id) {
    try {
      const response = await instance.delete(
        url.DELETE_CANCELED_ORDER(id),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async approveReturnProduct(id) {
    try {
      const response = await instance.put(
        url.APPROVE_RETURN_ORDER(id),
        {},
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
};
