import styles from "./NewProfileLayout.module.scss";
import { SellerHeader, Footer } from "components";
import { Outlet } from "react-router-dom";

const NewProfileLayout = ({ bgColor = "#fff" }) => {
  return (
    <>
      <SellerHeader />
      <main className={styles.main} style={{ backgroundColor: bgColor }}>
        <Outlet />
      </main>
      <footer className={styles.footer}>
        <Footer />
      </footer>
    </>
  );
};

export { NewProfileLayout };
