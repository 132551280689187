import styles from "./ShowMyService.module.scss";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { axiosProduct } from "plugins/axios";
import { ServiceInfo } from "components";
import { Helmet } from "react-helmet";

import { useNavigate } from "react-router-dom";

const ShowMyService = () => {
  const navigate = useNavigate();

  const [service, setService] = useState({});
  const params = useParams();

  const getService = async () => {
    let result = await axiosProduct.getService(params.id);
    setService(result.info || {});
  };

  useEffect(() => {
    getService();
  }, [params.id]);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{service.name ? "Услуга " + service.name : "Услуга"}</title>

        <meta
          name="description"
          content={
            "Shalfey : " +
            service.name +
            ". " +
            String(service.description).substring(0, 50)
          }
        />
        <link rel="canonical" href={`/service/${params.id}`} />
      </Helmet>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "4vh",
          width: "79%",
        }}
      >
        <button onClick={() => {}} className={styles.cancel}>
          Назад
        </button>
        <button
          className={styles.further}
          onClick={() => navigate(`/seller/upduteservice/${params.id}`)}
        >
          Редактировать
        </button>
      </div>
      <div className={styles.section}>
        <ServiceInfo service={service} />
      </div>
    </div>
  );
};
export { ShowMyService };
