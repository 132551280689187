export const NEW_MESSAGE = {
  type: "NM",
  text: "Новое сообщение",
  color: "#EAF8E7",
};
export const PAGE_REJECTED = {
  type: "ER",
  text: "Страница отклонена модератором",
  color: "#FFFDEE",
};
export const PAGE_APPROVED = {
  type: "SC",
  text: "Страница одобрена модератором",
  color: "#EAF8E7",
};
export const NEW_REVIEW = {
  type: "NR",
  text: "Новый отзыв",
  color: "#EAF8E7",
};
export const RETURN_ASKED = {
  type: "RA",
  text: "Запрошен возврат",
  color: "#EAF8E7",
};
export const ORDER_REJECTED = {
  type: "OR",
  text: "Заказ отменен",
  color: "#FFFDEE",
};

export const NOTIFICATIONS_OBJ = {
  PAGE_REJECTED,
  PAGE_APPROVED,
  NEW_MESSAGE,
  NEW_REVIEW,
  RETURN_ASKED,
  ORDER_REJECTED,
};
export const NOTIFICATIONS_MAP = [
  PAGE_REJECTED,
  PAGE_APPROVED,
  NEW_MESSAGE,
  NEW_REVIEW,
  RETURN_ASKED,
  ORDER_REJECTED,
];
