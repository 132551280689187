import React, { useEffect, useState } from "react";
import { SellerProfileInfo } from "components";
import styles from "./Profile.module.scss";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import { sellerButtons } from "constants/profileButton";

const SellerProfile = () => {
  const [searchParams] = useSearchParams();
  const [activeWindow, setActiveWindow] = useState({
    title: "Профиль продавца",
    type: "",
    component: <>В разработке</>,
  });

  useEffect(() => {
    let tab = searchParams.get("tab");
    if (tab) {
      let activeTab = sellerButtons.find((item) => item.type === tab);
      setActiveWindow({
        ...activeTab,
        component: activeTab?.component || <>В разработке</>,
      });
    } else {
      setActiveWindow({
        ...sellerButtons[0],
        component: sellerButtons[0]?.component || <>В разработке</>,
      });
    }
  }, [searchParams.get("tab")]);

  return (
    <section className={styles.container}>
      <Helmet title={activeWindow.title}>
        <meta name="description" content={"Shalfey : " + activeWindow.title} />
        <link rel="canonical" href="/seller" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={styles.content}>
        <div className={styles.menu} style={{ width: "40%" }}>
          <SellerProfileInfo />
        </div>
        <div>{activeWindow.component}</div>
      </div>
    </section>
  );
};

export { SellerProfile };
