import * as url from "../endpoints";
import { instance, config, configExcel } from "./createAxios";
import { removeToken } from "../token";

export const axiosMyShop = {
  async getMyGoods(params = "") {
    try {
      const response = await instance.get(url.GET_MY_GOODS(params), config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getMyGoodsFilter() {
    try {
      const response = await instance.get(url.GET_MY_GOODS_FILTER(), config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async deleteMyGood(id) {
    try {
      const response = await instance.delete(url.DELETE_MY_GOOD(id), config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async deleteMyGoodService(id) {
    try {
      const response = await instance.delete(
        url.DELETE_MY_GOOD_SERVICE(id),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async changeStatusMyGood(id, status = "") {
    try {
      const response = await instance.put(
        url.CHANGE_STATUS_MY_GOOD(id),
        { status },
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async changeStatusMyGoodService(id, status = "") {
    try {
      const response = await instance.put(
        url.CHANGE_STATUS_MY_GOOD_SERVICE(id),
        { status },
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getSymptomsMyGood() {
    try {
      const response = await instance.get(url.GET_SYMPTOMS_MY_GOOD(), config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async setTagsMyGood(id, symptoms = []) {
    try {
      const response = await instance.put(
        url.SET_TAGS_MY_GOOD(id),
        { symptoms },
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getExcelMyGoods(products = "") {
    try {
      const response = await instance.get(
        url.GET_EXCEL_MY_GOODS(products),
        configExcel
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },

  async getPinnedReviews(page = 1) {
    try {
      const response = await instance.get(url.GET_PINNED_REVIEWS(page), config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getReviewList(page = 1, params = "") {
    try {
      const response = await instance.get(
        url.GET_REVIEW_LIST(page, params),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getChatList(query = "", page = 1) {
    try {
      const response = await instance.get(
        url.GET_CHAT_LIST(query, page),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getProductForReviews(id, type = "archive") {
    try {
      const response = await instance.get(
        url.GET_PRODUCT_FOR_REVIEWS(id, type),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getChatOrder(order = "", page = 1) {
    try {
      const response = await instance.get(
        url.GET_CHAT_ORDER(order, page),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getReviewsForProduct(id, type = "archive") {
    try {
      const response = await instance.get(
        url.GET_REVIEWS_FOR_PRODUCT(id, type),
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async pinReview(id) {
    try {
      const response = await instance.put(url.PIN_REVIEW(id), config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async answerReview(id, comment = "") {
    try {
      const response = await instance.put(
        url.ANSWER_REVIEW(id),
        { comment },
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async sendMessageChat(order = "", text = "") {
    try {
      const response = await instance.post(
        url.SEND_MESSAGE_CHAT(order),
        { text },
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
};
