import React, { useState, useEffect } from "react";
import styles from "./Profile.module.scss";
import { SellerProfileInfo, Notification } from "components";
import {
  NOTIFICATIONS_OBJ,
  NOTIFICATIONS_MAP,
} from "constants/notificationType";
import { axiosSeller } from "plugins/axios";
import { useNavigate } from "react-router-dom";

const SellerNotifications = () => {
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(2);
  const [wasLastPage, setWasLastPage] = useState(false);

  const onScroll = (event) => {
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight >=
      event.target.scrollHeight - 10;
    if (scrollBottom && currentPage < nextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getNotifications = async () => {
    await axiosSeller.getSellerNotificationsCount();
    let resNotifications = await axiosSeller.getSellerNotifications(
      currentPage
    );
    if (!resNotifications?.results) {
      setWasLastPage(true);
      return;
    }
    setNotifications([
      ...(currentPage === 1 ? [] : notifications),
      ...resNotifications.results.map((notification) => ({
        ...notification,
        color:
          NOTIFICATIONS_MAP.find(
            (notific) => notific.type === notification.notification_type
          )?.color || "#EAF8E7",
        button: {
          title:
            notification.notification_type ===
            NOTIFICATIONS_OBJ.PAGE_REJECTED.type
              ? "Редактировать\u00A0страницу"
              : notification.notification_type ===
                NOTIFICATIONS_OBJ.NEW_MESSAGE.type
              ? "Перейти\u00A0в\u00A0чат"
              : notification.notification_type ===
                NOTIFICATIONS_OBJ.NEW_REVIEW.type
              ? "Перейти\u00A0к\u00A0отзывам"
              : notification.notification_type ===
                NOTIFICATIONS_OBJ.RETURN_ASKED.type
              ? "Перейти\u00A0к\u00A0возвратам"
              : "Просмотрено",
        },
      })),
    ]);
    setNextPage(resNotifications.next ? currentPage + 1 : currentPage);
    setWasLastPage(!resNotifications.next);
  };

  useEffect(() => {
    if (!wasLastPage) {
      getNotifications();
    }
  }, [currentPage, wasLastPage]);

  const checkNotification = async (id) => {
    let notification = notifications.find(
      (notification) => notification.id === id
    );

    switch (notification.notification_type) {
      case NOTIFICATIONS_OBJ.PAGE_REJECTED.type: {
        let additionalInfo = notification.additional_info.split(": ");
        navigate(
          `${
            additionalInfo[0] === "product"
              ? "/seller/upduteproduct/"
              : "/seller/upduteservice/"
          }${additionalInfo[1]}`
        );
        break;
      }
      case NOTIFICATIONS_OBJ.NEW_MESSAGE.type: {
        await axiosSeller.setNotificationIsSeen(id);
        navigate(
          `/seller/shop/chat?chat=${notification.additional_info || ""}`
        );
        break;
      }
      case NOTIFICATIONS_OBJ.NEW_REVIEW.type: {
        await axiosSeller.setNotificationIsSeen(id);
        navigate(`/seller/shop/reviews`);
        break;
      }
      case NOTIFICATIONS_OBJ.RETURN_ASKED.type: {
        await axiosSeller.setNotificationIsSeen(id);
        navigate(`/seller/deliveries/canceled_orders`);
        break;
      }
      default: {
        let resCheck = await axiosSeller.setNotificationIsSeen(id);
        if (!resCheck) {
          let newNotifications = notifications.filter(
            (notification) => notification.id !== id
          );
          setNotifications(newNotifications);
          await axiosSeller.getSellerNotificationsCount();
        }
      }
    }
  };

  return (
    <div className={styles.seller_notifications__cont}>
      <div className={styles.seller_notifications__menu}>
        <SellerProfileInfo />
      </div>
      <div
        className={styles.seller_notifications__container}
        onScroll={onScroll}
      >
        {!!notifications.length ? (
          notifications.map((notification, index) => (
            <Notification
              key={index}
              notification={notification}
              checkNotification={() => checkNotification(notification.id)}
            />
          ))
        ) : (
          <div className={styles.seller_notifications__empty}>
            Нет уведомлений
          </div>
        )}
      </div>
    </div>
  );
};

export { SellerNotifications };
