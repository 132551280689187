import React, { useState, useEffect } from "react";
import styles from "./FunnelAnalytics.module.scss";
import { axiosSeller, axiosMyShop } from "plugins/axios";
import { getNoun } from "plugins/getNoun";
import { Button, Tabs, ModalWindow, Checkbox, Input } from "components";
import { Helmet } from "react-helmet";
import details from "assets/img/details.svg";
import star from "assets/img/new_star.svg";
import search_icon from "assets/img/search.svg";
import EmptyProduct from "assets/img/empty_product.png";
import { Bar } from "react-chartjs-2";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SALES_FUNNEL, PROFILE } from "constants/url";

const TABS = [
  { name: "week", title: "Неделя" },
  { name: "month", title: "Месяц" },
  { name: "quarter", title: "3 месяца" },
  { name: "half", title: "6 месяцев" },
  { name: "year", title: "Год" },
];

const FunnelAnalytics = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [data, setData] = useState(1);
  const [currentTab, setCurrentTab] = useState(
    searchParams.get("tab") || "overall_data"
  );
  const [sorting, setSorting] = useState("week");
  const [myGoods, setMyGoods] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  const [productAnalytics, setProductAnalytics] = useState({});
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({
    id: 0,
    vendor_code: 0,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [wasLastList, setWasLastList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(0);

  let marksCount = [
    {
      star_count: 5,
      star: "звезд",
      mark_count: 0,
      percent: 0,
      increase_count: 0,
    },
    {
      star_count: 4,
      star: "звезды",
      mark_count: 0,
      percent: 0,
    },
    {
      star_count: 3,
      star: "звезды",
      mark_count: 0,
      percent: 0,
    },
    {
      star_count: 2,
      star: "звезды",
      mark_count: 0,
      percent: 0,
    },
    {
      star_count: 1,
      star: "звезда",
      mark_count: 0,
      percent: 0,
    },
  ];

  const marks = (items) => {
    marksCount[0].mark_count = items.rating_details?.five_count.all_count;
    marksCount[0].increase_count =
      items.rating_details?.five_count.increase_count;
    marksCount[0].percent =
      (marksCount[0].mark_count * 100) / items.overall_ratings?.rating_count;

    marksCount[1].mark_count = items.rating_details?.four_count.all_count;
    marksCount[1].increase_count =
      items.rating_details?.four_count.increase_count;
    marksCount[1].percent =
      (marksCount[1].mark_count * 100) / items.overall_ratings?.rating_count;

    marksCount[2].mark_count = items.rating_details?.three_count.all_count;
    marksCount[2].increase_count =
      items.rating_details?.three_count.increase_count;
    marksCount[2].percent =
      (marksCount[2].mark_count * 100) / items.overall_ratings?.rating_count;

    marksCount[3].mark_count = items.rating_details?.two_count.all_count;
    marksCount[3].increase_count =
      items.rating_details?.two_count.increase_count;
    marksCount[3].percent =
      (marksCount[3].mark_count * 100) / items.overall_ratings?.rating_count;

    marksCount[4].mark_count = items.rating_details?.one_count.all_count;
    marksCount[4].increase_count =
      items.rating_details?.one_count.increase_count;
    marksCount[4].percent =
      (marksCount[4].mark_count * 100) / items.overall_ratings?.rating_count;
  };

  const labels = ["Предыдущий квартал", "В этом квартале"];

  const dataBar = {
    labels,
    datasets: [
      {
        maxBarThickness: "65",
        data: [
          productAnalytics?.sales?.previous,
          productAnalytics?.sales?.current,
        ],
        backgroundColor: [
          "hsl(" + Math.random() * 360 + ", 100%, 95%)",
          "hsl(" + Math.random() * 360 + ", 100%, 95%)",
        ],
        borderRadius: 10,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: { display: false },
        border: { color: "#EAF8E7", width: 2 },
      },
      y: { display: false },
    },
    responsive: true,
    layout: { padding: { top: 30 } },
    plugins: {
      legend: { display: false },
      datalabels: {
        color: "#20B900",
        weight: "600",
        anchor: "end",
        align: "start",
        offset: -25,
        formatter: function (value, context) {
          return `${value} шт.`;
        },
      },
    },
  };

  const exportToExel = async () => {
    if (currentTab === "overall_data") {
      let res = await axiosSeller.getFunnelExcel(sorting);
      const blob = new Blob([res], {
        type: "application/vnd.openxmlformats-officedocument.speadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download =
        "Продажи (общие показатели) " +
        TABS.find((el) => el.name === sorting).title +
        ".xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      let res = await axiosSeller.getProductExcel(selectedProduct.id, sorting);
      const blob = new Blob([res], {
        type: "application/vnd.openxmlformats-officedocument.speadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Рейтинг товара " + selectedProduct.vendor_code + ".xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const onScroll = (event) => {
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight >=
      event.target.scrollHeight - 10;
    if (scrollBottom) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getMyGoods = async () => {
    let resMyGoods = await axiosMyShop.getMyGoods(
      `page=${currentPage}&vendor_code=${searchText}`
    );
    if (resMyGoods?.response?.status === 403) {
      navigate(PROFILE.url);
    }
    if (!resMyGoods?.results?.products.length || resMyGoods?.message) {
      setWasLastList(true);
      return;
    }
    setMyGoods([...myGoods, ...resMyGoods.results.products]);
    setIsSearch(false);
  };

  const getFunnelAnalytics = async () => {
    if (currentTab === "overall_data") {
      let resp = await axiosSeller.getFunnel(sorting);
      setData({
        average_price: resp.average_price || 0,
        order_count: resp.order_count || 0,
        overall_sum: resp.overall_sum || 0,
        return_cancel_count: resp.return_cancel_count || 0,
      });
    } else {
      if (selectedProduct.id !== 0) {
        let resp = await axiosSeller.getProductAnalytics(
          selectedProduct.id,
          sorting
        );
        setProductAnalytics(resp);
      }
    }
  };

  useEffect(() => {
    if (isSearch) {
      getMyGoods();
      setIsSearch(false);
    }
  }, [isSearch]);

  useEffect(() => {
    if (!wasLastList && previousPage !== currentPage) {
      getMyGoods();
    }
  }, [currentPage, previousPage, wasLastList]);

  useEffect(() => {
    setCurrentTab(searchParams.get("tab"));
    getFunnelAnalytics();
  }, [sorting, currentTab]);

  useEffect(() => {
    setCurrentTab(searchParams.get("tab"));
  }, [searchParams.get("tab")]);

  return (
    <section className={styles.analytics}>
      <Helmet title="Воронка продаж">
        <meta name="description" content="Shalfey : Воронка продаж" />
        <link rel="canonical" href="/seller/analytics/sales_funnel" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div style={{ display: "flex" }}>
        <h1 className={styles.heading}>Воронка продаж</h1>
        <Button
          text="Продажи (общие показатели)"
          bgColor={currentTab === "overall_data" ? "#fff" : "unset"}
          color="#5F6B69"
          padding="0vh 0.5vw"
          marginRight="2vw"
          click={() => {
            navigate(SALES_FUNNEL.url + "?tab=overall_data");
            setSorting("week");
          }}
        />
        <Button
          text="Рейтинг товара"
          bgColor={currentTab === "product" ? "#fff" : "unset"}
          color="#5F6B69"
          padding="0vh 0.5vw"
          click={() => {
            navigate(SALES_FUNNEL.url + "?tab=product");
            setSorting("quarter");
          }}
        />
      </div>
      {currentTab === "overall_data" ? (
        <div className={styles.container}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1 className={styles.heading}>Продажи (общие показатели)</h1>
            <Button
              text="Скачать файл"
              padding="1vh 1vw"
              bgColor="#EAF8E7"
              color="#5F6B69"
              prepend={
                <img
                  src={details}
                  alt="Скачать файл"
                  style={{ aspectRatio: 1, height: "2vh", margin: "0" }}
                />
              }
              click={exportToExel}
            />
          </div>
          <div className={styles.periods}>
            {TABS.map((per) => (
              <span
                key={per.name}
                className={`${styles.span} ${
                  sorting === per.name ? styles.selected : ""
                }`}
                onClick={() => setSorting(per.name)}
              >
                {per.title}
              </span>
            ))}
          </div>
          <div style={{ display: "flex" }}>
            <div className={styles.item}>
              <p>Объем продаж:</p>
              <p>{data.overall_sum} руб</p>
            </div>
            <div className={styles.item}>
              <p>Количество заказов:</p>
              <p>{data.order_count}</p>
            </div>
            <div className={styles.item}>
              <p>Средний чек:</p>
              <p>{data.average_price} руб</p>
            </div>
            <div className={styles.item}>
              <p>Количество отмен и возвратов:</p>
              <p>{data.return_cancel_count}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h1 className={styles.heading}>Рейтинг товара</h1>
              {showAnalytics && (
                <>
                  <h2 className={styles.vendor_code}>
                    Арт. {selectedProduct.vendor_code}
                  </h2>
                  <Button
                    text="Выбрать другой товар"
                    padding="1.5vh 1vw"
                    light
                    click={() => setIsModalOpen(true)}
                  />
                </>
              )}
            </div>
            {!showAnalytics ? (
              <Button
                text="Выбрать товар"
                padding="1.5vh 1vw"
                newLight
                click={() => setIsModalOpen(true)}
              />
            ) : (
              <Button
                text="Скачать файл"
                padding="1vh 1vw"
                bgColor="#EAF8E7"
                color="#5F6B69"
                prepend={
                  <img
                    src={details}
                    alt="Скачать файл"
                    style={{ aspectRatio: 1, height: "2vh", margin: "0" }}
                  />
                }
                click={exportToExel}
              />
            )}
          </div>
          <Tabs
            tabs={[
              { name: "quarter", title: "Квартал" },
              { name: "month", title: "Месяц" },
              { name: "week", title: "Неделя" },
            ]}
            currentTab={sorting}
            margin="5vh 0 6vh"
            changeTab={setSorting}
          />
          {showAnalytics ? (
            <>
              <p className={styles.title}>Продажи товара:</p>
              <div style={{ height: "300px" }}>
                <Bar options={options} data={dataBar} />
              </div>
              <Tabs
                tabs={[
                  {
                    title: (
                      <p className={styles.rating}>
                        <b>Рейтинг товара:</b>
                        <img src={star} className={styles.star} alt="Звезда" />
                        {productAnalytics.overall_ratings?.average_rating?.toFixed(
                          1
                        )}
                        &nbsp; ({productAnalytics.overall_ratings?.rating_count}
                        &nbsp;
                        {getNoun(
                          productAnalytics.overall_ratings?.rating_count,
                          "оценка",
                          "оценки",
                          "оценок"
                        )}
                        )
                      </p>
                    ),
                  },
                  {
                    title: (
                      <p className={styles.rating}>
                        <b>Доля SKU в общем объеме продаж:&nbsp;</b>
                        {productAnalytics.sku_percent}%
                      </p>
                    ),
                  },
                ]}
                margin="5vh 0"
              />
              <div style={{ display: "flex" }}>
                <div className={styles.marks}>
                  <p className={styles.title}>
                    Прирост оценок{" "}
                    <span>
                      +{productAnalytics.rating_details?.increase || 0}
                    </span>
                  </p>
                  {marks(productAnalytics)}
                  <div className={styles.star_part}>
                    <div>
                      {productAnalytics.overall_ratings?.average_rating
                        ? [
                            ...Array(
                              Math.round(
                                productAnalytics.overall_ratings?.average_rating
                              )
                            ),
                          ].map((x, i) => (
                            <img key={i} src={star} alt="Звезда" />
                          ))
                        : null}
                    </div>
                    <p>{`${
                      productAnalytics.overall_ratings?.average_rating?.toFixed(
                        1
                      ) || 0
                    } / 5`}</p>
                  </div>
                  <div className={styles.line} />
                  {marksCount.map((item, index) => (
                    <div key={index} className={styles.star_line}>
                      <h1>{`${item.star_count} ${item.star}`}</h1>
                      <div className={styles.mark_count}>
                        <div
                          style={{
                            width: `${item.percent}%`,
                            height: "100%",
                            background: "#DF730E",
                          }}
                        />
                      </div>
                      <h2>
                        {item.mark_count}&nbsp;
                        {!!item.increase_count && <b>+{item.increase_count}</b>}
                      </h2>
                    </div>
                  ))}
                </div>
                <div className={styles.info}>
                  <div className={styles.info_item}>
                    <p>Выкупы</p>
                    <p>{productAnalytics.ransoms}%</p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div>Для просмотра информации выберите товар</div>
          )}
        </div>
      )}
      <ModalWindow
        isOpen={isModalOpen}
        width="43vw"
        onClose={() => setIsModalOpen(false)}
      >
        <div className={styles.form}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "5vh",
            }}
          >
            <h1 className={styles.heading}>Выберите товар</h1>
            <Input
              light
              placeholder="Поиск товара"
              value={searchText}
              prepend={
                <img
                  src={search_icon}
                  style={{ height: "2.5vh" }}
                  alt="search"
                  onClick={() => {
                    setMyGoods([]);
                    setCurrentPage(1);
                    setWasLastList(false);
                    setPreviousPage(0);
                    setIsSearch(true);
                  }}
                />
              }
              onChange={(ev) => setSearchText(ev.target.value)}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  setMyGoods([]);
                  setCurrentPage(1);
                  setWasLastList(false);
                  setPreviousPage(0);
                  setIsSearch(true);
                }
              }}
            />
          </div>
          <div className={styles.scroll} onScroll={onScroll}>
            <table>
              <thead>
                <tr>
                  <td>Выбрать</td>
                  <td>Название</td>
                  <td style={{ paddingLeft: "2vw" }}>Фото</td>
                  <td>Артикул</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {myGoods.map((good) => (
                  <tr key={good.id}>
                    <td>
                      <Checkbox
                        isSelected={good.id === selectedProduct.id}
                        width="1vw"
                        height="1vw"
                        marginRight="1vw"
                        styleBack={{ padding: "2px", borderRadius: "0.5vh" }}
                        change={() =>
                          setSelectedProduct({
                            id: good.id,
                            vendor_code: good.vendor_code,
                          })
                        }
                      />
                    </td>
                    <td>
                      <div className={styles.name}>{good.name}</div>
                    </td>
                    <td>
                      <img
                        src={good.pic_urls[0]?.pic_url || EmptyProduct}
                        alt={good.name}
                      />
                    </td>
                    <td>{good.vendor_code}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Button
            text="Выбрать товар"
            padding="2vh 0vh"
            click={() => {
              if (selectedProduct.id !== 0) {
                getFunnelAnalytics();
                setIsModalOpen(false);
                setShowAnalytics(true);
              }
            }}
          />
        </div>
      </ModalWindow>
    </section>
  );
};

export { FunnelAnalytics };
