import React, { useState, useEffect } from "react";
import styles from "./CanceledOrders.module.scss";
import {
  Button,
  Input,
  ModalOneButton,
  ModalWindow,
  ModalTwoButtons,
  Textarea,
  Checkbox,
  Documents,
  ModalNoButton,
  OrderDetails,
} from "components";

import EmptyProduct from "assets/img/empty_product.png";
import search_icon from "assets/img/search.svg";
import cancel from "assets/img/cancel.svg";
import details from "assets/img/details.svg";
import filter from "assets/img/filter.svg";
import letter from "assets/img/letter.svg";
import documentation from "assets/img/documentation.svg";
import edit from "assets/img/review_edit.svg";
import { vh, vw } from "plugins/convertPXtoVHVW";

import { Helmet } from "react-helmet";
import { axiosDeliveries, axiosSeller } from "plugins/axios";
import { DELIVERIES_STATUSES } from "constants/purchaseStatuses";
import { useOutsideClick } from "hooks";
import { PROFILE } from "constants/url";
import { useNavigate } from "react-router-dom";

const CanceledOrders = () => {
  const navigate = useNavigate();
  const [returnOrders, setReturnOrders] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [wasLastList, setWasLastList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(0);

  const [editItem, setEditItem] = useState({});
  const [modalDetails, setModalDetails] = useState(false);

  const [modalDocuments, setModalDocuments] = useState(false);
  const [orderItemDocuments, setOrderItemDocuments] = useState(0);
  const [documentUpload, setDocumentUpload] = useState([]);

  const [modalChat, setModalChat] = useState(false);
  const [editChatItem, setEditChatItem] = useState({});

  const [modalReturnOrder, setModalReturnOrder] = useState(false);

  const [modalChangeStatus, setModalChangeStatus] = useState(false);
  const [editStatusItem, setEditStatusItem] = useState("");
  const [newStatus, setNewStatus] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [date, setDate] = useState("");
  const [typesOfMedicine, setTypesOfMedicine] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [medicine, setMedicine] = useState([]);
  const [categories, setCategories] = useState([]);
  const [openMedicineSelect, setOpenMedicineSelect] = useState(false);
  const [openCategoriesSelect, setOpenCategoriesSelect] = useState(false);
  const [openDateSelect, setOpenDateSelect] = useState(false);

  const refCategoriesSelect = useOutsideClick(() =>
    setOpenCategoriesSelect(false)
  );
  const refMedicineSelect = useOutsideClick(() => setOpenMedicineSelect(false));
  const refDateSelect = useOutsideClick(() => setOpenDateSelect(false));

  const getReturnOrders = async () => {
    await axiosSeller.getSellerNotificationsCount();
    let result = await axiosDeliveries.getCanceledOrders(
      {
        query: searchText,
        categories,
        types_of_medicine: medicine,
        date,
      },
      currentPage
    );
    if (result?.status === 403) {
      navigate(PROFILE.url);
    }
    if (!result?.results?.length || result?.message) {
      setWasLastList(true);
      return;
    }
    setPreviousPage(currentPage);
    setReturnOrders([...returnOrders, ...result.results]);
  };

  const getFilterParams = async () => {
    let resp = await axiosDeliveries.getFilterParams();
    setTypesOfMedicine(resp.types_of_medicine || []);
    setCategoriesData(resp.categories || []);
  };

  const downloadDocs = () => {
    documentUpload.forEach(async (element) => {
      let resp = await axiosDeliveries.downloadDoc(element.file_url);
      const blob = new Blob([resp], {
        type: "application/pdf",
      });
      let a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.setAttribute("download", element.name);
      a.click();
    });
  };

  const editStatus = async () => {
    await axiosDeliveries.changeOrderStatus(editStatusItem, newStatus);
    setReturnOrders([]);
    setWasLastList(false);
    setCurrentPage(1);
    setPreviousPage(0);
    setModalChangeStatus(false);
  };

  const sendMessage = async () => {
    await axiosDeliveries.sendMessageCanseledOrder(
      editChatItem.id,
      editChatItem.comment
    );
    setEditChatItem({});
    setModalChat(false);
  };

  const deleteReturnOrder = async (order_number) => {
    await axiosDeliveries.deleteReturnProduct(order_number);
    setReturnOrders([]);
    setWasLastList(false);
    setCurrentPage(1);
    setPreviousPage(0);
  };

  const approveReturnOrder = async (id) => {
    await axiosDeliveries.approveReturnProduct(id);
    setReturnOrders([]);
    setWasLastList(false);
    setCurrentPage(1);
    setPreviousPage(0);
  };

  const onScroll = (event) => {
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight >=
      event.target.scrollHeight - 10;
    if (scrollBottom) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    if (!wasLastList && previousPage !== currentPage) {
      getReturnOrders();
    }
  }, [currentPage, previousPage, wasLastList]);

  useEffect(() => {
    getFilterParams();
  }, []);

  return (
    <div className={styles.content} onScroll={onScroll}>
      <Helmet title="Список заявок на возврат">
        <meta name="description" content="Shalfey : Список заявок на возврат" />
        <link rel="canonical" href="/seller/deliveries/canceled_orders" />
      </Helmet>
      <h1 className={styles.heading_title}>Список заявок на возврат</h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "4vh",
        }}
      >
        <Input
          light
          placeholder="Введите артикул или название товара"
          value={searchText}
          fontColor="#5F6B69"
          width="18vw"
          prepend={
            <img
              src={search_icon}
              style={{ height: "2.5vh" }}
              alt="search"
              onClick={() => {
                setReturnOrders([]);
                setWasLastList(false);
                setCurrentPage(1);
                setPreviousPage(0);
              }}
            />
          }
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              setReturnOrders([]);
              setWasLastList(false);
              setCurrentPage(1);
              setPreviousPage(0);
            }
          }}
        />
        <div
          id="image"
          className={styles.navImage}
          onClick={() => setIsModalOpen(true)}
        >
          <img src={filter} alt="menu" />
          Фильтр
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <td>Номер заказа</td>
            <td>Статус</td>
            <td>Cостав заказа</td>
            <td>Причина возврата</td>
            <td style={{ paddingLeft: "2vw", paddingRight: "10vw" }}>Фото</td>
            <td>Информация</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {returnOrders.map((returnOrder) => (
            <tr key={returnOrder.order_number}>
              <td>{returnOrder.order_number}</td>
              <td>
                <p
                  style={{
                    cursor: "pointer",
                    color:
                      returnOrder.status === "PD"
                        ? "#20B900"
                        : returnOrder.status === "CL"
                        ? "#DC7413"
                        : "#5F6B69",
                  }}
                  onClick={() => {
                    if (returnOrder.status !== "PD") {
                      setEditStatusItem(returnOrder.order_number);
                      setModalChangeStatus(true);
                    }
                  }}
                >
                  {DELIVERIES_STATUSES.find(
                    (el) => el.code === returnOrder.status
                  )
                    ? DELIVERIES_STATUSES.find(
                        (el) => el.code === returnOrder.status
                      ).text
                    : ""}
                  <img
                    src={edit}
                    alt="Редактировать"
                    style={{ marginLeft: "0.5vw" }}
                  />
                </p>
              </td>
              <td>
                <div className={styles.product}>
                  <p>Арт. {returnOrder.content.product.vendor_code}</p>
                  <p className={styles.one_line}>
                    {returnOrder.content.product.name}
                  </p>
                  <div className={styles.price}>
                    <p>{returnOrder.content.count} шт.</p>
                    <p>{returnOrder.content.price} р.</p>
                  </div>
                </div>
              </td>
              <td>
                {returnOrder.return_reason}
                <Button
                  text="Начать чат"
                  padding="1vh 0.9vw"
                  marginTop="1vh"
                  bgColor="#EAF8E7"
                  color="#5F6B69"
                  fontWeight="400"
                  prepend={
                    <img
                      src={letter}
                      alt="Начать чат"
                      style={{
                        width: "1vw",
                        height: "1.4vh",
                        margin: "0",
                        borderRadius: 0,
                      }}
                    />
                  }
                  click={() => {
                    setEditChatItem({
                      id: returnOrder.id,
                    });
                    setModalChat(true);
                  }}
                />
              </td>
              <td>
                <img
                  src={returnOrder.pic_url || EmptyProduct}
                  alt={returnOrder.order_number}
                />
              </td>
              <td>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setEditItem(returnOrder.order_number);
                    setModalDetails(true);
                  }}
                >
                  <img
                    src={details}
                    alt="Детали заказа"
                    style={{ marginRight: "0.5vw" }}
                  />
                  Детали заказа
                </p>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOrderItemDocuments(returnOrder.order_number);
                    setModalDocuments(true);
                  }}
                >
                  <img
                    src={documentation}
                    alt="Документация"
                    style={{ marginRight: "0.5vw" }}
                  />
                  Документация
                </p>
              </td>
              <td>
                <div style={{ flexDirection: "column" }}>
                  <Button
                    text="Оформить возврат"
                    padding="1vh 0.9vw"
                    marginBottom="1vh"
                    click={() => {
                      approveReturnOrder(returnOrder.id);
                      setModalReturnOrder(true);
                    }}
                  />
                  <Button
                    text="Отменить возврат"
                    padding="1vh 0.9vw"
                    bgColor="transparent"
                    color="#DC7413"
                    prepend={
                      <img
                        src={cancel}
                        alt="Удалить"
                        style={{ width: "1vw", height: "1.3vh", margin: "0" }}
                      />
                    }
                    click={() => deleteReturnOrder(returnOrder.id)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ModalWindow
        isOpen={isModalOpen}
        width="32vw"
        onClose={() => setIsModalOpen(false)}
      >
        <div className={styles.form}>
          <p>Фильтры</p>
          <div ref={refCategoriesSelect} className={styles.custom_select}>
            <button
              className={openCategoriesSelect ? styles.open : null}
              onClick={() => {
                if (categoriesData.length)
                  setOpenCategoriesSelect(!openCategoriesSelect);
              }}
            >
              <span>Категория</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={vh(12)}
                height={vh(13)}
                viewBox="0 0 12 13"
                fill="none"
              >
                <path
                  d="M1 4.5C1 4.5 4.04201 7.00735 5.76697 8.32346C5.90441 8.42833 6.09483 8.42413 6.22983 8.31614C8.04139 6.86689 9.12532 5.99974 11 4.5"
                  stroke="#5F6B69"
                  strokeWidth="0.61828"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <ul className={openCategoriesSelect ? styles.open : null}>
              <div>
                {categoriesData.map((symptom) => (
                  <li
                    key={symptom.id}
                    className={symptom.isSelected ? styles.select : null}
                  >
                    <span>
                      <label>
                        <Checkbox
                          isSelected={categories.includes(symptom.id)}
                          width={vh(16)}
                          height={vh(16)}
                          marginRight={vw(10)}
                          styleBack={{ borderRadius: vh(3) }}
                          change={() =>
                            setCategories(
                              categories.includes(symptom.id)
                                ? categories.filter(
                                    (newValue) => newValue !== symptom.id
                                  )
                                : [...categories, symptom.id]
                            )
                          }
                        />
                        {symptom.name}
                      </label>
                    </span>
                  </li>
                ))}
              </div>
            </ul>
          </div>
          <div ref={refMedicineSelect} className={styles.custom_select}>
            <button
              className={openMedicineSelect ? styles.open : null}
              onClick={() => {
                if (typesOfMedicine.length)
                  setOpenMedicineSelect(!openMedicineSelect);
              }}
            >
              <span>Вид медицины</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={vh(12)}
                height={vh(13)}
                viewBox="0 0 12 13"
                fill="none"
              >
                <path
                  d="M1 4.5C1 4.5 4.04201 7.00735 5.76697 8.32346C5.90441 8.42833 6.09483 8.42413 6.22983 8.31614C8.04139 6.86689 9.12532 5.99974 11 4.5"
                  stroke="#5F6B69"
                  strokeWidth="0.61828"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <ul className={openMedicineSelect ? styles.open : null}>
              <div>
                {typesOfMedicine.map((symptom) => (
                  <li
                    key={symptom.id}
                    className={symptom.isSelected ? styles.select : null}
                  >
                    <span>
                      <label>
                        <Checkbox
                          isSelected={medicine.includes(symptom.id)}
                          width={vh(16)}
                          height={vh(16)}
                          marginRight={vw(10)}
                          styleBack={{ borderRadius: vh(3) }}
                          change={() =>
                            setMedicine(
                              medicine.includes(symptom.id)
                                ? medicine.filter(
                                    (newValue) => newValue !== symptom.id
                                  )
                                : [...medicine, symptom.id]
                            )
                          }
                        />
                        {symptom.name}
                      </label>
                    </span>
                  </li>
                ))}
              </div>
            </ul>
          </div>
          <div ref={refDateSelect} className={styles.custom_select}>
            <button
              className={openDateSelect ? styles.open : null}
              onClick={() => setOpenDateSelect(!openDateSelect)}
            >
              <span>По дате</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={vh(12)}
                height={vh(13)}
                viewBox="0 0 12 13"
                fill="none"
              >
                <path
                  d="M1 4.5C1 4.5 4.04201 7.00735 5.76697 8.32346C5.90441 8.42833 6.09483 8.42413 6.22983 8.31614C8.04139 6.86689 9.12532 5.99974 11 4.5"
                  stroke="#5F6B69"
                  strokeWidth="0.61828"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <ul className={`${openDateSelect ? styles.open : null}`}>
              <div>
                {[
                  { value: "new", name: "Сначала новые" },
                  { value: "old", name: "Сначала старые" },
                ].map((category) => (
                  <li
                    key={category.value}
                    onClick={() => setDate(category.value)}
                  >
                    <span
                      className={date === category.value ? styles.select : null}
                    >
                      {category.name}
                    </span>
                  </li>
                ))}
              </div>
            </ul>
          </div>
          <Button
            text="Готово"
            padding="1.6vh 2.5vh"
            margin="0 0 3vh"
            click={() => {
              setReturnOrders([]);
              setWasLastList(false);
              setCurrentPage(1);
              setPreviousPage(0);
            }}
          />
          <Button
            text="Очистить"
            bgColor="transparent"
            color="#9CA5A3"
            padding="1.6vh 2.5vh"
            click={() => {
              setMedicine([]);
              setCategories([]);
              setDate("");
            }}
          />
        </div>
      </ModalWindow>
      {modalChangeStatus && (
        <ModalOneButton
          title="Изменение статуса заказа"
          description={
            <>
              <div
                className={`${styles.status} ${
                  newStatus === "processing" ? styles.selected : ""
                }`}
                onClick={() => setNewStatus("processing")}
              >
                В обработке
              </div>
              <div className={styles["horizontal-line"]} />
              <div
                className={`${styles.status} ${
                  newStatus === "sent" ? styles.selected : ""
                }`}
                onClick={() => setNewStatus("sent")}
              >
                Передан в доставку
              </div>
              <div className={styles["horizontal-line"]} />
              <div
                className={`${styles.status} ${
                  newStatus === "delivered" ? styles.selected : ""
                }`}
                onClick={() => setNewStatus("delivered")}
              >
                Доставлен
              </div>
              <div className={styles["horizontal-line"]} />
              <div
                className={`${styles.status} ${
                  newStatus === "canceled" ? styles.selected : ""
                }`}
                onClick={() => setNewStatus("canceled")}
              >
                Отменен
              </div>
            </>
          }
          width="30vw"
          buttonStyle={{
            width: "100%",
            padding: `1.4vh 0`,
            fontSize: "1.8vh",
            lineHeight: "2vh",
          }}
          button={{
            title: "Сохранить",
            action: editStatus,
          }}
          descriptionAlign="left"
          close={() => setModalChangeStatus(false)}
        />
      )}
      {modalChat && (
        <ModalTwoButtons
          title="Ваше сообщение"
          description={
            <Textarea
              text={editChatItem.comment}
              placeholder="Введите сообщение"
              rows={3}
              width="-webkit-fill-available"
              resize="none"
              change={(event) =>
                setEditChatItem({
                  ...editChatItem,
                  comment: event.target.value,
                })
              }
            />
          }
          width="45vw"
          buttonStyle={{
            width: "100%",
            padding: `1.8vh 0`,
            fontSize: "1.8vh",
            lineHeight: "2vh",
          }}
          button={{
            title: "Написать",
            action: sendMessage,
          }}
          descriptionAlign="left"
          close={() => setModalChat(false)}
        />
      )}
      {modalDocuments && (
        <ModalTwoButtons
          title="Документация"
          description={
            <Documents
              order_number={orderItemDocuments}
              setSelectedDoc={setDocumentUpload}
            />
          }
          width="45vw"
          buttonStyle={{
            width: "100%",
            padding: `1.8vh 0`,
            fontSize: "1.8vh",
            lineHeight: "2vh",
          }}
          button={{
            title: "Скачать",
            action: downloadDocs,
          }}
          descriptionAlign="left"
          close={() => setModalDocuments(false)}
        />
      )}
      {modalDetails && (
        <ModalNoButton
          title=""
          description={<OrderDetails order_number={editItem} />}
          width="45vw"
          height="90vh"
          overflowY="scroll"
          descriptionAlign="left"
          close={() => setModalDetails(false)}
        />
      )}
      {modalReturnOrder && (
        <ModalOneButton
          title="Вы оформили возврат"
          description={
            <div style={{ fontSize: "1.8vh" }}>
              Покупателю будет оформлен возврат уплаченных за
              товар денежных средств
            </div>
          }
          button={{
            title: "Понятно",
            action: () => setModalReturnOrder(false),
          }}
          buttonStyle={{
            width: "100%",
            padding: `1.4vh 0`,
            fontSize: "1.8vh",
            lineHeight: "2vh",
          }}
          width="27vw"
          close={() => setModalReturnOrder(false)}
        />
      )}
    </div>
  );
};

export { CanceledOrders };
