import vector_25 from "assets/img/vector_25.svg";
import arrow_2 from "assets/img/arrow_2.svg";
import arrow_green from "assets/img/arrow_green.svg";
import birdie from "assets/img/birdie.svg";
import main_photo from "assets/img/main_photo.svg";
import small_photo from "assets/img/small_photo.svg";

import styles from "./UpdateProduct.module.scss";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { axiosSeller } from "plugins/axios";
import { Checkbox } from "components";

const UpdateProduct = () => {
  const navigate = useNavigate();
  const [medicine, setMedicine] = useState([]);
  const [symptom_groups, setSymptom_groups] = useState([]);
  const [indications, setIndications] = useState([]);
  const [medicine_forms, setMedicine_forms] = useState([]);
  const [year_classification, setYear_classification] = useState([]);
  const [categories, setCategories] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [openCategory, setOpenCategory] = useState([]);

  const params = useParams();

  const [openSelect, setOpenSelect] = useState({
    openMed: false,
    openSymptomGroups: false,
    openYearClassification: false,
    openIllnesses: false,
    openCat: false,
    openMedicineForms: false,
  });

  const [productFields, setProductFields] = useState({
    name: "",
    brand: "",
    type_of_medicine: [],
    composition: "",
    contradictions: "",
    storage_conditions: "",
    medicine_format: [],
    manufacturer_country: "",
    indications: [],
    categories: [],
    symptoms: [],
    length: "",
    width: "",
    height: "",
    weight: "",
    count: "",
    vat: "",
    vendor_code: "",
    barcode: "",
    price: "",
    description: "",
    dosage: "",
    application_methods: "",
    old_photo: [],
    delivery_type: "CR",
  });
  const [pics, setPics] = useState([
    {
      id: 1,
      pic: main_photo,
      nameProductFields: "pic_1",
      picProductFields: "",
      oldPic: "",
      oldId: "",
    },
    {
      id: 2,
      pic: small_photo,
      nameProductFields: "pic_2",
      picProductFields: "",
      oldPic: "",
      oldId: "",
    },
    {
      id: 3,
      pic: small_photo,
      nameProductFields: "pic_3",
      picProductFields: "",
      oldPic: "",
      oldId: "",
    },
    {
      id: 4,
      pic: small_photo,
      nameProductFields: "pic_4",
      picProductFields: "",
      oldPic: "",
      oldId: "",
    },
    {
      id: 5,
      pic: small_photo,
      nameProductFields: "pic_5",
      picProductFields: "",
      oldPic: "",
      oldId: "",
    },
    {
      id: 6,
      pic: small_photo,
      nameProductFields: "pic_6",
      picProductFields: "",
      oldPic: "",
      oldId: "",
    },
    {
      id: 7,
      pic: small_photo,
      nameProductFields: "pic_7",
      picProductFields: "",
      oldPic: "",
      oldId: "",
    },
    {
      id: 8,
      pic: small_photo,
      nameProductFields: "pic_8",
      picProductFields: "",
      oldPic: "",
      oldId: "",
    },
    {
      id: 9,
      pic: small_photo,
      nameProductFields: "pic_9",
      picProductFields: "",
      oldPic: "",
      oldId: "",
    },
  ]);
  const getRawProduct = async () => {
    let result = await axiosSeller.getRawProduct(params.id);
    result.pic_urls?.map((item) => {
      let i = 0;
      let newPics = [...pics];
      while (i < pics.length) {
        if (newPics[i].oldId === item.id) {
          break;
        }
        if (pics[i].picProductFields === "" && pics[i].oldPic === "") {
          newPics[i].oldPic = item.pic_url;
          newPics[i].oldId = item.id;
          setPics(newPics);
          break;
        }
        i++;
      }
    });
    setProductFields({
      ...productFields,
      name: result.name,
      brand: result.brand,
      type_of_medicine: result.type_of_medicine,
      composition: result.composition,
      contradictions: result.contraindications,
      storage_conditions: result.storage_conditions,
      medicine_format: [result.medicine_format],
      manufacturer_country: result.manufacturer_country,
      indications: result.indications,
      categories: result.category_list,
      symptoms: result.symptoms,
      year_classification: result.year_classification,
      length: result.length,
      width: result.width,
      height: result.height,
      weight: result.weight,
      count: result.box_count,
      vat: result.vat,
      vendor_code: result.vendor_code,
      barcode: result.barcode,
      price: result.price,
      description: result.description,
      dosage: result.dosage,
      application_methods: result.application_methods,
    });
  };

  const getMedicineCreateProduct = async () => {
    let result = await axiosSeller.getMedicineCreateProduct();

    setMedicine(result.types_of_medicine || []);
    setSymptom_groups(result.symptom_groups || []);
    setIndications(result.indications || []);
    setMedicine_forms(result.medicine_forms || []);
    setYear_classification(result.year_classification || []);
  };

  const checkProperties = (obj) => {
    for (let key in obj) {
      if (
        (obj[key] === null || obj[key] == "" || obj[key] == []) &&
        key !== "old_photo"
      )
        return key;
    }
    return false;
  };

  const updateProduct = async () => {
    let i = 0;
    let newProductFields = { ...productFields };
    while (i < pics.length) {
      if (pics[i].picProductFields !== "") {
        newProductFields[pics[i].nameProductFields] = pics[i].picProductFields;
        setProductFields(newProductFields);
      } else if (pics[i].oldPic !== "") {
        newProductFields.old_photo.push(pics[i].oldId);
        setProductFields(newProductFields);
      }
      i++;
    }
    setErrorMessage("");
    let error = checkProperties(productFields);
    if (error) {
      setErrorMessage("Заполните все поля." + error);
    } else {
      let result = await axiosSeller.updateProduct(params.id, newProductFields);
      if (result.status === 204) {
        navigate("/seller/shop/products");
      }
      if (result.status === 400) {
        if (
          result.data.Error === "Such product already exists" ||
          result.data.Error === "Product with such vendor code already exists"
        ) {
          setErrorMessage(
            "Такой товар уже существует.(У каждого продукта должен быть уникальный артикул.)"
          );
        }
        if (result.data.Error === "Necessary fields are not given") {
          setErrorMessage("Заполните все поля.");
        }
      }
    }
  };
  const openCategories = async () => {
    let result = await axiosSeller.getCategoriesCreateProduct(
      productFields.type_of_medicine
    );

    setCategories(result.categories || []);
  };

  const AddImages = (image) => {
    let i = 0;
    let newPics = [...pics];
    while (i < pics.length) {
      if (pics[i].picProductFields === "" && pics[i].oldPic === "") {
        newPics[i].picProductFields = image;
        setPics(newPics);
        break;
      }
      i++;
    }
  };
  const RemoveImages = (id) => {
    let newPics = [...pics];
    newPics[id].oldPic = "";
    newPics[id].oldId = "";
    newPics[id].picProductFields = "";
    setPics(newPics);
  };

  const addOrRemoveProductFields = (item, field) => {
    if (productFields[field]?.find((element) => element === item.id)) {
      if (field === "medicine_format") {
        setProductFields({ ...productFields, [field]: [] });
      } else {
        setProductFields({
          ...productFields,
          [field]: productFields[field]?.filter((n) => {
            return n != item.id;
          }),
        });
      }
    } else {
      if (field === "medicine_format") {
        setProductFields({
          ...productFields,
          [field]: [item.id],
        });
      } else {
        setProductFields({
          ...productFields,
          [field]: [...productFields[field], item.id],
        });
      }
    }
  };

  const showCategories = (category, field) => {
    return (
      <div style={{ marginLeft: "4vh" }}>
        {category?.children?.length === 0 || !category.children ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            onClick={() => {
              addOrRemoveProductFields(category.obj, field);
            }}
          >
            {productFields[field]?.find(
              (element) => element === category.obj.id
            ) ? (
              <img
                src={birdie}
                alt="birdie"
                style={{ height: "1.5vh", paddingRight: "1.5vh" }}
              />
            ) : null}
            <h1
              style={
                productFields[field]?.find(
                  (element) => element === category.obj.id
                )
                  ? { color: "#20b900" }
                  : null
              }
              className={styles.empty_category}
            >
              {category.obj.name}
            </h1>
          </div>
        ) : (
          <div>
            <div
              className={
                openCategory?.find((element) => element === category.obj.id)
                  ? styles.open_category
                  : styles.category
              }
              onClick={() => {
                if (
                  openCategory?.find((element) => element === category.obj.id)
                ) {
                  setOpenCategory(
                    openCategory?.filter((n) => {
                      return n != category.obj.id;
                    })
                  );
                } else {
                  setOpenCategory([...openCategory, category.obj.id]);
                }
              }}
            >
              <h1>{category.obj.name}</h1>
              {openCategory.find((element) => element === category.obj.id) ? (
                <img
                  src={arrow_green}
                  alt="arrow_green"
                  style={{ height: "0.8vh", padding: "0 2vh" }}
                />
              ) : (
                <img
                  src={arrow_2}
                  alt="arrow_2"
                  style={{ height: "1.5vh", paddingLeft: "1vh" }}
                />
              )}
            </div>
            {openCategory.find((element) => element === category.obj.id) && (
              <div className={styles.part_categories}>
                {category.children.map((item, index) =>
                  item?.children?.length === 0 || !category.children ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: "4vh",
                      }}
                      key={index}
                    >
                      {productFields[field].find(
                        (element) => element === item.obj.id
                      ) ? (
                        <img
                          src={birdie}
                          alt="birdie"
                          style={{ height: "1.5vh", paddingRight: "1.5vh" }}
                        />
                      ) : null}
                      <h1
                        className={styles.empty_category}
                        style={
                          productFields[field].find(
                            (element) => element === item.obj.id
                          )
                            ? { color: "#20b900" }
                            : null
                        }
                        onClick={() => {
                          addOrRemoveProductFields(item.obj, field);
                        }}
                      >
                        {item.obj.name}
                      </h1>
                    </div>
                  ) : (
                    showCategories(item, field)
                  )
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    getMedicineCreateProduct();
    getRawProduct();
  }, []);
  return (
    <div className={styles.container}>
      <Helmet title="Редактировать Товар">
        <link rel="canonical" href="/seller/createproduct" />
        <meta
          name="description"
          content='Форма добавления товара на сайт "Shalfey".'
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div>
        <h1 className={styles.heading}>Редактирование товара</h1>
      </div>

      <div>
        <div style={{ display: "flex" }}>
          <div className={styles.part}>
            <div>
              <div className={styles.title}>
                <h3>Основная информация</h3>
              </div>
              <div className={styles.content}>
                <input
                  value={productFields.name}
                  placeholder="Название"
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      name: event.target.value,
                    });
                  }}
                />
                <input
                  value={productFields.brand}
                  placeholder="Бренд"
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      brand: event.target.value,
                    });
                  }}
                />

                <div
                  className={styles.medicine}
                  onClick={() =>
                    setOpenSelect({
                      openMed: !openSelect.openMed,
                    })
                  }
                >
                  <p>Вид медицины</p>
                  <img src={vector_25} alt="vector_25" />
                </div>
                {openSelect.openMed && (
                  <div className={styles.types_of_medicine}>
                    {medicine?.map((item, index) => (
                      <div
                        key={index}
                        className={styles.type}
                        onClick={() =>
                          setProductFields({
                            ...productFields,
                            type_of_medicine: item.id,
                            categories: [],
                          })
                        }
                      >
                        <p
                          className={
                            productFields.type_of_medicine === item.id
                              ? styles.open_medicine
                              : null
                          }
                        >
                          {item.name}
                        </p>
                      </div>
                    ))}
                  </div>
                )}

                <input
                  value={productFields.composition}
                  placeholder="Состав"
                  onChange={(event) =>
                    setProductFields({
                      ...productFields,
                      composition: event.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div>
              <div className={styles.title}>
                <h3> Дополнительная информация </h3>
              </div>
              <div className={styles.content}>
                <input
                  value={productFields.description}
                  placeholder="Описание"
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      description: event.target.value,
                    });
                  }}
                />
                <input
                  value={productFields.contradictions}
                  placeholder="Противопоказания"
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      contradictions: event.target.value,
                    });
                  }}
                />
                <div
                  className={styles.medicine}
                  onClick={() => {
                    setOpenSelect({
                      openMedicineForms: !openSelect.openMedicineForms,
                    });
                  }}
                >
                  <p> Форма выпуска</p>
                  <img src={vector_25} alt="vector_25" />
                </div>
                {openSelect.openMedicineForms && (
                  <div>
                    {medicine_forms?.map((item, index) =>
                      showCategories(item, "medicine_format")
                    )}
                  </div>
                )}
                <div>
                  <input
                    value={productFields.application_methods}
                    placeholder="Способ применения"
                    style={{ width: "22vw" }}
                    onChange={(event) => {
                      setProductFields({
                        ...productFields,
                        application_methods: event.target.value,
                      });
                    }}
                  />
                  <input
                    value={productFields.dosage}
                    placeholder="Дозировка"
                    style={{ width: "22vw" }}
                    onChange={(event) => {
                      setProductFields({
                        ...productFields,
                        dosage: event.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <input
                    value={productFields.storage_conditions}
                    placeholder="Условия хранения и срок годности"
                    style={{ width: "22vw" }}
                    onChange={(event) => {
                      setProductFields({
                        ...productFields,
                        storage_conditions: event.target.value,
                      });
                    }}
                  />

                  <input
                    value={productFields.manufacturer_country}
                    placeholder="Страна производитель"
                    style={{ width: "22vw" }}
                    onChange={(event) => {
                      setProductFields({
                        ...productFields,
                        manufacturer_country: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className={styles.title_pic}>
                <h1>Фото</h1>
                <label
                  for="picfile"
                  style={{ color: "#20B900", cursor: "pointer" }}
                >
                  Добавить фото
                </label>
                <input
                  type="file"
                  id="picfile"
                  name="picfile"
                  onChange={(event) => {
                    AddImages(event.target.files[0]);
                  }}
                />
              </div>
              <div className={styles.pics}>
                <div
                  className={
                    pics[0].picProductFields !== "" || pics[0].oldPic !== ""
                      ? styles.main_photo
                      : null
                  }
                  onClick={() => {
                    RemoveImages(0);
                  }}
                >
                  <img
                    src={
                      pics[0].picProductFields !== ""
                        ? URL.createObjectURL(pics[0].picProductFields)
                        : pics[0].oldPic !== ""
                        ? pics[0].oldPic
                        : pics[0].pic
                    }
                    alt={pics[0].nameProductFields}
                    style={{ height: "28vh", width: "28vh" }}
                  />
                </div>
                <div className={styles.small_photo}>
                  {pics.slice(1).map((item, index) => (
                    <div
                      key={index}
                      className={
                        item.picProductFields !== "" || item.oldPic !== ""
                          ? styles.photo
                          : null
                      }
                      onClick={() => {
                        RemoveImages(item.id - 1);
                      }}
                    >
                      <img
                        src={
                          item.picProductFields !== ""
                            ? URL.createObjectURL(item.picProductFields)
                            : item.oldPic !== ""
                            ? item.oldPic
                            : item.pic
                        }
                        alt={item.nameProductFields}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.part}>
            <div>
              <div className={styles.title}>
                <h3> Категории</h3>
              </div>
              <div className={styles.content}>
                <div
                  className={styles.medicine}
                  onClick={() => {
                    openCategories();
                    setOpenSelect({
                      openCat: !openSelect.openCat,
                    });
                  }}
                >
                  <p>Категории</p>
                  <img src={vector_25} alt="vector_25" />
                </div>
                {openSelect.openCat && (
                  <div>
                    {categories?.map((item, index) =>
                      showCategories(item, "categories")
                    )}
                  </div>
                )}
                <div
                  className={styles.medicine}
                  onClick={() => {
                    setOpenSelect({
                      openIllnesses: !openSelect.openIllnesses,
                    });
                  }}
                >
                  <p>Показания</p>
                  <img src={vector_25} alt="vector_25" />
                </div>
                {openSelect.openIllnesses && (
                  <div>
                    {indications?.map((item, index) =>
                      showCategories(item, "indications", "")
                    )}
                  </div>
                )}
                <div
                  className={styles.medicine}
                  onClick={() => {
                    setOpenSelect({
                      openSymptomGroups: !openSelect.openSymptomGroups,
                    });
                  }}
                >
                  <p>Симптомы</p>
                  <img src={vector_25} alt="vector_25" />
                </div>
                {openSelect.openSymptomGroups && (
                  <div>
                    {symptom_groups?.map((item, index) =>
                      showCategories(item, "symptoms", "symptoms_names")
                    )}
                  </div>
                )}
                <div
                  className={styles.medicine}
                  onClick={() => {
                    setOpenSelect({
                      openYearClassification:
                        !openSelect.openYearClassification,
                    });
                  }}
                >
                  <p>Возраст</p>
                  <img src={vector_25} alt="vector_25" />
                </div>
                {openSelect.openYearClassification && (
                  <div>
                    {year_classification?.map((item, index) =>
                      showCategories(item, "year_classification")
                    )}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={styles.title}>
                <h3>Габариты</h3>
              </div>
              <div className={styles.content}>
                <input
                  value={productFields.length}
                  placeholder="Длина"
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      length: event.target.value,
                    });
                  }}
                />
                <input
                  value={productFields.width}
                  placeholder="Ширина"
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      width: event.target.value,
                    });
                  }}
                />
                <input
                  value={productFields.height}
                  placeholder="Высота"
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      height: event.target.value,
                    });
                  }}
                />
                <input
                  value={productFields.weight}
                  placeholder="Вес"
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      weight: event.target.value,
                    });
                  }}
                />
                <input
                  type="number"
                  min="0"
                  value={productFields.count}
                  placeholder="Количество в упаковке"
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      count: event.target.value,
                    });
                  }}
                />
                <div className={styles.tax}>
                  <h1> НДС (налог на добавленную стоимость)</h1>
                  <div className={styles.types_tax}>
                    <div
                      onClick={() => {
                        setProductFields({
                          ...productFields,
                          vat: "SV",
                        });
                      }}
                    >
                      {productFields.vat === "SV" ? "◉ 10%" : "〇 10%"}
                    </div>
                    <div
                      onClick={() => {
                        setProductFields({
                          ...productFields,
                          vat: "BV",
                        });
                      }}
                    >
                      {productFields.vat === "BV" ? "◉ 20%" : "〇 20%"}
                    </div>
                    <div
                      onClick={() => {
                        setProductFields({
                          ...productFields,
                          vat: "NV",
                        });
                      }}
                    >
                      {productFields.vat === "NV"
                        ? "◉ Не облагается"
                        : "〇 Не облагается"}
                    </div>
                  </div>
                </div>
                <input
                  value={productFields.vendor_code}
                  placeholder="Артикул"
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      vendor_code: event.target.value,
                    });
                  }}
                />
                <input
                  value={productFields.barcode}
                  placeholder="Штрих-код"
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      barcode: event.target.value,
                    });
                  }}
                />
                <input
                  type="number"
                  min="0"
                  value={productFields.price}
                  placeholder="Цена"
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      price: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <h3 className={styles.title}>Cпособ доставки</h3>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            className={styles.delivery}
            style={{
              backgroundColor:
                productFields.delivery_type === "CR" ? "#EFFAF0" : "#F8FBF9",
            }}
            onClick={() =>
              setProductFields({
                ...productFields,
                delivery_type: "CR",
              })
            }
          >
            <div style={{ display: "flex" }}>
              <Checkbox
                isSelected={productFields.delivery_type === "CR"}
                width="2vh"
                height="2vh"
                marginRight="1vw"
                styleBack={{ padding: "1px", borderRadius: "3px" }}
                change={() =>
                  setProductFields({
                    ...productFields,
                    delivery_type: "CR",
                  })
                }
              />
              <p className={styles.checkbox}>Доставка курьером</p>
            </div>
            <p>(курьер забирает посылку со склада)</p>
          </div>
          <div
            className={styles.delivery}
            style={{
              backgroundColor:
                productFields.delivery_type === "TM" ? "#EFFAF0" : "#F8FBF9",
            }}
            onClick={() =>
              setProductFields({
                ...productFields,
                delivery_type: "TM",
              })
            }
          >
            <div style={{ display: "flex" }}>
              <Checkbox
                isSelected={productFields.delivery_type === "TM"}
                width="2vh"
                height="2vh"
                marginRight="1vw"
                styleBack={{ padding: "1px", borderRadius: "3px" }}
                change={() =>
                  setProductFields({
                    ...productFields,
                    delivery_type: "TM",
                  })
                }
              />
              <p className={styles.checkbox}>Доставка через почтоматы </p>
            </div>
            <p>(доставка отправок осуществляется через почтомат)</p>
          </div>
        </div>
        <div>
          <div>
            {errorMessage && <p>{errorMessage}</p>}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "7vh",
              }}
            >
              <button
                className={styles.cancel}
                onClick={() => {
                  navigate("/seller/shop/products");
                }}
              >
                Отмена
              </button>

              <button
                className={styles.further}
                onClick={() => {
                  updateProduct();
                }}
              >
                Сохранить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { UpdateProduct };
