import React, { useState, useEffect } from "react";
import styles from "./MainAnalytics.module.scss";
import { Tabs } from "components";
import { axiosSeller } from "plugins/axios";
import { Bar } from "react-chartjs-2";

const inner = {
  id: "inner",
  afterDatasetsDraw(chart, args, pluginOptions) {
    const {
      ctx,
      data,
      chartArea: { left },
      scales: { x, y },
    } = chart;
    ctx.save();
    data.datasets[0].data.forEach((element, index) => {
      ctx.font = "400 16px Roboto";
      ctx.fillStyle = "gray";
      ctx.fillText(data.labels[index], left, y.getPixelForValue(index) - 25);
    });
  },
};

const TABS = [
  { name: "today", title: "Сегодня" },
  { name: "week", title: "За неделю" },
  { name: "month", title: "За месяц" },
  { name: "quarter", title: "За 3 месяца" },
  { name: "half", title: "За 6 месяцев" },
];

const MainAnalytics = () => {
  const [skuAnalytics, setSkuAnalytics] = useState([]);
  const [summary, setSummary] = useState({});
  const [sorting, setSorting] = useState("today");
  const [barHeight, setBarHeight] = useState(80);

  const labels = skuAnalytics?.map((el) => el.category_name);

  const data = {
    labels,
    datasets: [
      {
        barPercentage: 0.5,
        categoryPercentage: 1,
        data: skuAnalytics?.map((el) => el.category_percent),
        backgroundColor: skuAnalytics?.map(
          (el) => "hsl(" + Math.random() * 360 + ", 100%, 95%)"
        ),
        borderRadius: 10,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    maintainAspectRatio: false,
    scales: {
      x: { display: false },
      y: { display: false },
    },
    responsive: true,
    layout: {
      padding: { right: 35 },
    },
    plugins: {
      legend: { display: false },
      datalabels: {
        color: "#20B900",
        weight: "600",
        anchor: "end",
        align: "start",
        offset: -35,
        formatter: function (value, context) {
          return `${value}%`;
        },
      },
    },
  };

  const getMainAnalytics = async () => {
    let resp = await axiosSeller.getMain(sorting);
    setSummary({
      overall: resp.summary?.overall || 0,
      saved: resp.summary?.saved || 0,
      paid: resp.summary?.paid || 0,
    });
    setSkuAnalytics(resp.sku_analytics);
    setBarHeight(resp.sku_analytics?.length * 80 || 80);
  };

  useEffect(() => {
    getMainAnalytics();
  }, [sorting]);

  return (
    <section className={styles.container}>
      <Tabs tabs={TABS} currentTab={sorting} changeTab={setSorting} />
      <div style={{ display: "flex" }}>
        <div style={{ width: "30%" }}>
          <p className={styles.heading}>
            Сводка {sorting === "today" && "за "}
            <span>{TABS.find((el) => el.name === sorting).title}</span>
          </p>
          <p className={styles.title}>Всего получено</p>
          <p className={styles.price}>{summary.overall} руб.</p>
          <p className={styles.title}>Удержано</p>
          <p className={styles.price}>{summary.saved} руб.</p>
          <p className={styles.title}>Выплачено</p>
          <p className={styles.price}>{summary.paid} руб.</p>
          <p className={styles.title}>Штрафы</p>
          <p className={styles.price}>0 руб.</p>
        </div>
        <div style={{ width: "70%", height: barHeight }}>
          <p className={styles.heading}>Аналитика товаров по SKU</p>
          {!!skuAnalytics?.length ? (
            <Bar options={options} data={data} plugins={[inner]} />
          ) : (
            "Нет данных в заданный период"
          )}
        </div>
      </div>
    </section>
  );
};

export { MainAnalytics };
