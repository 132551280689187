import React from "react";
import styles from "./Notification.module.scss";
import notification_order from "assets/img/notification_order.svg";
import { Button } from "components/Inputs";
import { vh, vw } from "plugins/convertPXtoVHVW";
import { getFormattedDate } from "plugins/getFormattedDate";

const Notification = ({ notification = {}, checkNotification = () => {} }) => {
  return (
    <div
      className={styles.notification}
      style={{ backgroundColor: notification?.color || "#EAF8E7" }}
    >
      <div className={styles.notification__header}>
        <img src={notification_order} alt="Уведомление" />
        <span>Уведомление</span>
      </div>
      <div className={styles.notification__body}>
        <div className={styles.notification__left}>
          {notification?.cover_url ? (
            <img src={notification.cover_url} alt="Продукт" />
          ) : (
            <></>
          )}
          <div className={styles.notification__info}>
            <div className={styles.notification__info__type}>
              {notification?.header}
            </div>
            <div className={styles.notification__info__messages}>
              {!!notification?.messages?.length &&
                notification.messages.map((message, index) => (
                  <p key={index}>
                    {index === 0 ? "" : `${index}. `}
                    {message}
                  </p>
                ))}
            </div>
            <div className={styles.notification__info__date}>
              {getFormattedDate(notification?.update_date || "", true, true)}
            </div>
          </div>
        </div>
        <div className={styles.notification__right}>
          <Button
            text={notification?.button?.title || ""}
            bgColor="#20B900"
            color="#ffffff"
            fontSize={vh(18)}
            lineHeight={vh(22)}
            fontWeight="600"
            padding={`${vh(9)} ${vw(21.5)}`}
            borderRadius={vh(8)}
            click={checkNotification}
          />
        </div>
      </div>
    </div>
  );
};

export { Notification };
